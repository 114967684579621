'use strict'

const map_gui_to_api_hostname = {
    'sailingschedule.griegstar.com': 'https://sailingschedule-api.griegstar.com',
    'sailingschedule.gearbulk.com': 'https://sailingschedule-api.gearbulk.com',
    'sailingschedule.g2ocean.com': 'https://sailingschedule-api.g2ocean.com',
    'sailingschedule.azurewebsites.net': 'https://sailingschedule-api.azurewebsites.net',
}

const getApiDomain = () => {
    const gui_domain = getCookieValue("requested_host")

    if (gui_domain && map_gui_to_api_hostname[gui_domain]) {
        return map_gui_to_api_hostname[gui_domain]
    }

    return 'https://sailingschedule-api.g2ocean.com'
}

const getGuiDomain = () => {
    const gui_domain = getCookieValue("requested_host")

    if (gui_domain && map_gui_to_api_hostname[gui_domain]) {
        return `https://${gui_domain}`
    }

    return 'https://sailingschedule.g2ocean.com'
}

function getCookieValue(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

module.exports = {
    domain: getApiDomain(),
    guiDomain: getGuiDomain(),
    test: false
}