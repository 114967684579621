import Vue from 'vue'
import VueRouter from 'vue-router'
import ScheduleByTrade from '../views/ScheduleByTrade.vue'
import ScheduleByVessel from '../views/ScheduleByVessel.vue'
import ScheduleHistorical from '../views/ScheduleHistorical.vue'
import ScheduleByPort from '../views/ScheduleByPort.vue'
import Calendar from '../views/Calendar.vue'
import ScheduleByShipper from '../views/ScheduleByShipper.vue'
import Top from '../menu/Top.vue'
import SchedulePlanning from '../views/SchedulePlanning.vue'

const urls = require('../../config/urls')
const g2Color = '#408481'
Vue.use(VueRouter)

const props = {
  top: {logo: '/g2ocean.png', color: g2Color},
  default: true,
}
const catch404 =  {
  path: '*',
  components: {
    'top': Top,
    'default': () => {import(/* webpackChunkName: "error" */ '../router/Dummy.vue')},
  },
  props: props
}

const routes = [
  catch404,  
  {
    path: '/redirect',
    name: 'Redir',
    components: {
      'top': () => import(/* webpackChunkName: "error" */ '../router/Dummy.vue'),
      'default':  () => import(/* webpackChunkName: "LoginRedirect" */'../router/LoginRedirect.vue'), 
    },    
  },
  {
    path: '/bytrade/:id?',
    name: 'ScheduleByTrade',
    components: {
      'top': Top,
      'default': ScheduleByTrade,
    },
    props: props
  },  
  {
    path: '/byvessel/:id?',
    name: 'ScheduleByVessel',
    components: {
      'top': Top,
      'default': ScheduleByVessel
    },
    props: props
  },  
  {
    path: '/historical/:id?',
    name: 'ScheduleHistorical',
    components: {
      'top': Top,
      'default': ScheduleHistorical
    },
    props: props
  }, 
  {
    path: '/byport/:id?',
    name: 'ScheduleByPort',
    components: {
      'top': Top,
      'default': ScheduleByPort
    },
    props: props
  },  
  {
    path: '/calendar/',
    name: 'home',
    components: {
      'top': Top,
      'default': Calendar,
    },
    props: props
  }, 
  {
    path: '/scheduleplanning/',
    name: 'scheduleplanning',
    components: {
      'top': Top,
      'default': SchedulePlanning,
    },
    props: props
  }, 
  {
    path: '/byshipper/:id?',
    name: 'ScheduleByShipper',
    components: {
      'top': Top,
      'default': ScheduleByShipper,
    },
    props: props
  },   
  {
    path: '/',
    redirect: {name: 'home'},
  },
  {
    path: '/error',
    name: 'Error',
    components: {
      'top': Top,
      'default':  () => import(/* webpackChunkName: "error" */ '../views/Error.vue'), 
    },
    props: props
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {  
  const publicPages = ['/redirect', '/login'];
  const hasJWT = localStorage.getItem('jwt') != undefined
  const authRequired = !publicPages.includes(to.path) && !hasJWT
  if (authRequired) {
    //console.log('Need auth, setting callback ', to.fullPath)
    localStorage.setItem('callback', to.fullPath)
    window.location.href = urls.LOGIN
  } else {
    //console.log('Auth OK, intercepted this heading to ', to.fullPath)
    next()
  }
})

export default router
