<template>
  <v-app id="app" height="100%" >
    <v-fade-transition>
      <router-view name="top" />
    </v-fade-transition>
    <v-main class="main">
      <v-container class="py-0" fluid style="height: 100%; overflow: auto; background-color: #f5f5f5;">
            <v-row class="py-0" style="height: 100%;" >
              <v-col class="pa-0" style="height: 100%;">
                <router-view/>
              </v-col>
            </v-row>
      </v-container>
    </v-main>
  </v-app>
 </template>

<script>
const urls = require("../config/urls");

export default {
  name: "App",
  data: () => ({
    users: ["Developer"],
    vessel: null,
    loaded: false,
    openOffhire: null,
  }),
  mounted: function(){
  },
  computed: {
    showError() {
      return this.$store.state.error != "";
    },
    isSubPage() {
      return this.$route.name == "Editor";
    },
    isLeftShrunk() {
      return this.openOffhire && this.openOffhire['Offhire Id'] != null
    },
    name() {
      if (
        !this.loaded ||
        !this.$route.params.id ||
        !this.$store.state.vessels[this.$route.params.id]
      )
        return "";
      return this.$store.state.vessels[this.$route.params.id]["VESSELNAME"];
    },

  },
  methods: {
    reactToEnter(){
      console.log('You hit enter, ')
    },
    logoff() {
      window.location.href = urls.LOGOUT;
    },
    setProfile(item) {
      this.$store.dispatch("setUser", { user: item });
    },
    clickHandler(e){
      this.openOffhire = (!e || e === this.openOffhire) ? null : e
      this.$router.push({ name: this.$route.name, params: { id: this.openOffhire['Offhire Id'] } })
    }
  },
};
</script>


<style lang="scss">
html, body {
  height: 100%;
}
#app {
  height: 100%;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #254b65;
  font: 1em Lato;

}
.main {
  height: 100%;
  z-index: 1;
}
.smol {
  max-width: 440px;
}
.normal {
  width: 100%;
    -webkit-transition: max-width .5s ease-in-out;
    -moz-transition: max-width .5s ease-in-out;
    -o-transition: max-width .5s ease-in-out;
    transition: max-width .5s ease-in-out;
}
@font-face {
    font-family: LatoBlack;
    src: url('./fonts/Lato-Black.ttf');
}
@font-face {
    font-family: LatoLight;
    src: url('./fonts/Lato-Light.ttf');
}
@font-face {
    font-family: Lato;
    src: url('./fonts/Lato-Regular.ttf');
    font-weight: normal;
    font-style: normal;   
}
@font-face {
    font-family: Lato;
    src: url('./fonts/Lato-Bold.ttf');
    font-weight: bold;
    font-style: normal;   
}
@font-face {
    font-family: Lato;
    src: url('./fonts/Lato-Italic.ttf');
    font-weight: normal;
    font-style: italic;   
}
</style>
