<template>
  <v-container :fluid="this.$vuetify.breakpoint.lgAndDown" :class="`px-${getPaddingX} py-8`" style="height: 100%;">
    <v-row class="d-flex px-3 justify-space-between">
      <v-col xl="3" lg="4" md="6" sm="7" class=" px-0 d-flex justify-start">
        <v-autocomplete
            v-model="tradeCode"
            label="Trade"
            outlined
            hide-details
            dense
            :color="getOwnerColor"
            :items="tradeList"
            item-text="TradeCode"
            item-value="TradeCode"
            class="trade-select"
            @change="changeTrade"
        >
        </v-autocomplete>

        <v-checkbox
          v-model="arrival"
          hide-details
          dense
          :color="getOwnerColor"
          class="ml-4"
          label="Arrival">
        </v-checkbox>

        <v-checkbox
          v-model="departure"
          hide-details
          dense
          :color="getOwnerColor"
          class="ml-4"
          label="Departure">
        </v-checkbox>

      </v-col>

      <v-col lg="6" md="12" class="pa-0 mt-5 justify-end">
        <v-btn
          style="font-size: 11px;" elevation="0" @click="resetPortRotation"
          v-if="$store.state.loadedRotation?.length && !loading && !reloadMeansNoChange"
        >
          <span>Revert rotation</span>
          <v-icon :color="getOwnerColor" class="ml-2">mdi-restart</v-icon>
        </v-btn>
        <v-btn :disabled="fileUploading" style="font-size: 11px;" elevation="0" @click="generateAndSavePDF">
          <span>Generate pdf</span>
          <v-icon v-if="!fileUploading" :color="getOwnerColor" class="ml-2">mdi-file-pdf-box</v-icon>
          <v-progress-circular
              v-if="fileUploading"
              class="ml-2"
              :value="fileUploadPercentage"
              :indeterminate="fileUploadIndeterminate"
              :width="1"
              :size="18"
              :color="getOwnerColor"
          >
            {{ percentOrNothing }}
          </v-progress-circular>
        </v-btn>
        <v-btn style="font-size: 11px;" elevation="0" @click="openFileDialog" >
          PDF Archive
          <v-icon :color="getOwnerColor" class="ml-2">mdi-archive-arrow-down-outline</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <v-row class="pa-0">
      <v-col cols="12" fluid class=" justify-center pa-0">
        <v-card flat class="d-none pa-4 rounded-lg" style="width: 100%;">
          <pdf-table
            :schedule="filteredSchedule"
            :headers="headersPreppedForExport"
            :isHidden="isHidden"
            :arrival="arrival"
            :departure="departure"
            :isTrade="true"
            ref="exportTable"
            />
        </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center pt-0 pb-6">
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="mt-6"
            :color="getOwnerColor"
          ></v-progress-circular>
      <v-card flat class="d-flex pa-4 rounded-lg"  v-if="!loading" transition="fade-transition" style="overflow:auto;">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr >
                <th v-for="(head, index) in headers.slice(0, 1)" :key="index" class="empty-column first-headers" >
                  <span>{{head.text}}</span>
                </th>
                <th v-for="(head, index) in headers.slice(1, 2)" :key="'C'+index" class="first-column first-headers" >
                  <span>{{head.text}}</span>
                </th>
                <th v-for="(head, index) in headers.slice(2,-1)" :key="'A' + index" class="vessel-headers middle-columns" >
                  <span @click="clickHeader(head)">{{ head.text}}</span>
                </th>
                <th v-for="(head, index) in headers.slice(-1)" :key="'B' + index" class="vessel-headers last-column">
                  <span @click="clickHeader(head)" >{{ head.text}}</span>
                </th>
              </tr>
            </thead>
            <tbody id="dragTable">
              <tr
                v-for="(item, rowindex) in schedule"
                  :key="itemKey(item)"
                  class="sortableRow sortHandle handle"
                  @mouseover="selectItem(item)"
                  @mouseleave="unSelectItem()"
                >
                  <td v-for="(value, index) in item" :key="index" @click="clickCell(value, index)" :id="itemKey(item)+'_'+index" class="table-cells" :class="{'d-none': index=='i'}" style="cursor: grab;">
                    <div v-if="index==='newRow'">
                      <v-icon v-if="item === selectedItem && !isGhostRow(item)" x-small :color="getOwnerColor" style="cursor: pointer;" @click="addGhostRow(rowindex)">mdi-plus-box</v-icon>
                      <v-icon v-if="isGhostRow(item)" x-small style="color: indianred; cursor: pointer;" @click="removeGhostRow(rowindex)">mdi-minus-box</v-icon>
                    </div>
                    <div v-if="index=='port' && !isGhostRow(item)" style="font-weight: bold;cursor: pointer;" class="text-left" @click="toggleGhostHorizontal(item.port)">{{toTitleCase(item.port)}}</div>
                    <div v-if="!isSpecialValue(index) && !isGhostRow(item) && !isHidden(value)" style="cursor: pointer;" class="text-left">{{ printableDate(value) }}</div>
                    <div v-if="!isSpecialValue(index) && isGhostRow(item)" class="text-left"></div>
                    <div v-if="!isSpecialValue(index) && isHidden(value) && printableDate(value)!='-'" class="text-left"><v-btn style="color: rgb(64, 132, 129);" class="px-4" elevation="0" x-small color="#c3dad9">X</v-btn></div>
                  </td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-card>
        </v-col>
    </v-row>
    <files-dialog
      v-if="showFileDialog"
      :data="$route.params.id"
      :ownerColor="getOwnerColor"
      :closeHandler="closeFileDialog"
    ></files-dialog>
  </v-container>
</template>

<script>
import PdfTable from "../components/PdfTable.vue";
import _ from 'lodash';
const urls = require('../../config/urls')
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import { toTitleCase, getOwnerColor } from "../components/Tools"
import Sortable from 'sortablejs'
import FilesDialog from '../dialogs/FilesDialog.vue'

export default {
  name: "SailingSchedule",
  props: [],
  components: { PdfTable, FilesDialog },
  data: () => ({
    loading: false,
    snackbar: false,
    snackMessage: '',
    arrival: true,
    departure: true,
    tradeCode: null,
    selectedItem: false,
    tableSorting: {
    },
    sortChevrons: {
        0: '&nbsp;',
        1: '↑',
        2: '↓',
    },
    hiddenCells: [],
    showFileDialog: false,
    currentArchive: [],
    fileUploading: false,
    fileUploadIndeterminate: false,
    fileUploadPercentage: 0,
    schedule: [],
    ghost: {},
    itemKeys: new WeakMap(),
    currentItemKey: 0,
  }),
  watch: {
    '$route.params.id': function() {
      this.loading = true
      this.init().then(()=>{
        this.itemKeys = new WeakMap(),
        this.reRenderTable()
        this.$nextTick(() => {
          new Sortable(
            document.querySelector('#dragTable'),
            {
              draggable: '.sortableRow',
              handle: '.sortHandle',
              onEnd: this.dragReorder
            }
          )
        })
        this.loading = false
      })
    },
    '$store.state.redrawTable': function() {
      this.$forceUpdate()
    }
  },
  mounted: function () {
    this.loading = true
    this.init().then(() => {
      this.reRenderTable()
      /* eslint-disable no-new */
      this.$nextTick(() => {
        new Sortable(
          document.querySelector('#dragTable'),
          {
            draggable: '.sortableRow',
            handle: '.sortHandle',
            onEnd: this.dragReorder
          }
        )
      })
      if(!this.$route.params.id || this.$route.params.id== 'undefined') {
        this.$router.push({ name: this.$route.name, params: { id: this.$store.state.trades[0].TradeCode } })
      }      if(!this.$route.params.id || this.$route.params.id== 'undefined') {
        this.$router.push({ name: this.$route.name, params: { id: this.$store.state.trades[0].TradeCode } })
      }
      this.tradeCode = this.$route.params.id
      this.loading = false
    })
  },
  computed: {
    getOwnerColor,
    getPaddingX() {
      return this.$vuetify.breakpoint.smAndDown ? 5 : 15
    },
    reloadMeansNoChange(){
      const data = this.$store.state.schedules.filter(v => v.TradeCode === this.$route.params.id)
      if(!data) return []
      let portsRaw =  [... new Set(data.map(item => item.PortName))].sort((a,b) => (a> b) ? 1 : ((b > a) ? -1 : 0))
      let a = [...this.$store.state.loadedRotation]
      let b = portsRaw
      return _.isEqual(a, b)
    },
    percentOrNothing(){
      return this.fileUploadIndeterminate || this.fileUploadPercentage<1 ? '' : this.fileUploadPercentage
    },
    pdfArchiveFilename() {
      const date = new Date()
      return `${date.getFullYear().toString()}-${this.pad2(date.getMonth() + 1)}-${this.pad2(date.getDate())}_${this.pad2(date.getHours())}${this.pad2(date.getMinutes())}${this.pad2(date.getSeconds())}_${this.$route.params.id}_sched.pdf`
    },
    filteredSchedule(){
      const pcs = this.schedule;
      let result = []
      for(let pc of pcs){
        if(pc.port=='ghost') {
          result.push(pc)
          continue
        }
        let keys = Object.keys(pc).slice(3)
        const allIsHidden = keys.map( m=> Object.keys(pc[m]).length>0 ?
              this.hiddenCells.includes(pc[m]) : undefined).filter(f => f!=undefined).filter(f=> f==false).length==0
        if(!allIsHidden){
          result.push(pc)
        }
      }
      return result
    },
    tradeList() {
      if(!this.$store.state.trades) return []
      let sorted = this.$store.state.trades
      return sorted.sort((a, b) => a.TradeCode?.localeCompare(b.TradeCode))
    },
    headersPreppedForExport() {
      let data = this.schedule
      let portcalls = this.$store.state.schedules.filter(v => v.TradeCode === this.$route.params.id)
      if(!data || data.length==0) return []
      let headers = [
        { text: "PORT", value: "port", sortable: false, width: 150, filterable: false, groupable: true},
      ]
      let keys = [... new Set(portcalls.map(item => item.VesselVoyageNo))]
      keys = keys.filter(f => !this.allPortcallsMarkedHidden(f))
      const vessels = keys.map( m => portcalls.find(f => f.VesselVoyageNo == m)).sort((a, b) => (a.VoyageStartDate > b.VoyageStartDate) ? 1 : -1 )
      vessels.forEach(function(vessel) {
        headers.push({
          text: vessel.VesselName + "\n" + vessel.VVN,
          value: vessel,
          sortable: false,
          width: 100,
          filterable: false,
          groupable: false
        })
      })
      return headers
    },
    headers() {
      let data = this.schedule
      let portcalls = this.$store.state.schedules.filter(v => v.TradeCode === this.$route.params.id)
      if(!data || data.length==0) return []

      let headers = [
        {text: " ", value: "newRow", sortable: false, width: 150, filterable: false, groupable: true},
        { text: "PORT", value: "port", sortable: false, width: 150, filterable: false, groupable: true},
      ]

      let keys = [... new Set(portcalls.map(item => item.VesselVoyageNo))]
      const vessels = keys
        .map( m => portcalls.find(f => f.VesselVoyageNo == m)).sort((a, b) => (a.VoyageStartDate > b.VoyageStartDate) ? 1 : -1 )
      vessels.forEach(function(vessel) {
        headers.push({
          text: vessel.VesselName + "\n" + vessel.VVN,
          value: vessel,
          sortable: false,
          width: 100,
          filterable: false,
          groupable: false
        })
      })
      return headers
    },
    todaysDate() {
      return new Date().toLocaleString("en-uk").slice(0,-3)
    }
  },
  beforeUpdate() {
    this.tradeCode = this.$route.params.id
  },
  methods: {
    toTitleCase,
    async resetPortRotation(){
      await this.$store.dispatch("resetRotation")
      this.itemKeys = new WeakMap(),
      this.reRenderTable()
      this.$nextTick(() => {
        new Sortable(
          document.querySelector('#dragTable'),
          {
            draggable: '.sortableRow',
            handle: '.sortHandle',
            onEnd: this.dragReorder
          }
        )
      })
    },
    changeTrade(item){
      if(item != this.$route.params.id) {
        console.log('Changing URL should trigger watch')
        this.$router.push({ name: this.$route.name, params: { id: item } })
      }
    },
    reRenderTable(){
      const data = this.$store.state.schedules.filter(v => v.TradeCode === this.$route.params.id).sort((a, b) => (a.VoyageStartDate > b.VoyageStartDate) ? 1 : -1 )
      if(!data) return []
      let portsRaw =  [... new Set(data.map(item => item.PortName))].sort((a,b) => (a> b) ? 1 : ((b > a) ? -1 : 0))
      let ports = []
      if(this.$store.state.loadedRotation?.length){
        for(const port of this.$store.state.loadedRotation){
          if(portsRaw.includes(port) || port==='ghost'){
            ports.push(port)
          }
        }
        for(const port of portsRaw) {
          if(!ports.includes(port) && port!='ghost') {
            ports.push(port)
          }
        }
      } else {
        ports = portsRaw
      }


      if(!this.$store || !this.$store.state.schedules) return []
      let vvn = [... new Set(data.map(item => item.VesselVoyageNo))]
      this.schedule = []
      for(const key in ports){
        let port = ports[key]
        let portObj = {port: port}
        let testObj = {newRow: ''}
        let indexObj = {i: key}
        let vesselObj = {}
        if(port === 'ghost') {
          vesselObj = vvn.reduce(
            (accumulator, vvn) => ({...accumulator, [vvn]: {}}), {}
          )
        }else{
          const portcalls = data.filter(f => f.PortName === port)
          vesselObj = vvn.reduce((accumulator, vvn) => {
            const item = portcalls.find(p => p.VesselVoyageNo == vvn)
            return {...accumulator, [vvn]: {...item}}
          }, {})
        }
        let scheduleObj = {...indexObj, ...testObj, ...portObj, ...vesselObj}
        this.schedule.push(scheduleObj)
      }


      let portObj = {port: 'ghost'}
      let testObj = {newRow: ''}
      let indexObj = {i: null}
      let vesselObj = vvn.reduce(
        (accumulator, vvn) => ({...accumulator, [vvn]: {}}), {}
      )
      this.ghost = {...indexObj, ...testObj, ...portObj, ...vesselObj}
    },
    itemKey (item) {
      if (!this.itemKeys.has(item)) this.itemKeys.set(item, ++this.currentItemKey)
      return this.itemKeys.get(item)
    },
    isSpecialValue(index){
      return index=='i' || index=='port' || index=='newRow'
    },
    isGhostRow(item){
      return item.port==='ghost'
    },
    addGhostRow(index){
      let newGhost = {...this.ghost}
      this.currentItemKey++
      newGhost.i = this.itemKey(newGhost)
      this.schedule.splice(index+1, 0, newGhost)
    },
    removeGhostRow(index){
      this.schedule.splice(index, 1)
    },
    pad2(n){
      return n < 10 ? '0' + n : n
    },
    closeFileDialog(){
      this.showFileDialog = false
    },
    openFileDialog(){
      this.showFileDialog = true
    },
    printableDate(value) {
      let arrivalDate = new Date(value['ArrivalDate']).toLocaleDateString("en-US", {month: 'short', day: 'numeric'}) 
      let departureDate = new Date(value['DepartureDate']).toLocaleDateString("en-US", {month: 'short', day: 'numeric'}) 
      if (this.arrival && this.departure) {
        return value && value['ArrivalDate'] && value['DepartureDate'] ? arrivalDate + "\n" + departureDate : '-'
      } else if (this.arrival && !this.departure) {
        return value && value['ArrivalDate'] ? arrivalDate : '-'
      } else if (!this.arrival && this.departure) {
        return value && value['DepartureDate'] ? departureDate : '-'
      }
    },
    selectItem (item) {
      this.selectedItem = item
    },
    unSelectItem() {
      this.selectedItem = false
    },
    updateTable() {
      this.$store.dispatch("redrawTable")
    },
    isHidden(value){
      if(!value || value && Object.keys(value).length<=0 || !this.hiddenCells || this.hiddenCells.length==0){
        return false
      }
      return _.some(this.hiddenCells, value)
    },
    getID(item, index){
      if(index==='port' || index==="test"){
        return ''
      }
      return item ? `${item.PortName}|${item.VesselName}` : '-'
    },
    allPortcallsMarkedHidden(vvn){
      if(!vvn) {
        return false
      }
      const pcs = this.schedule;
      const filteredPcs = pcs.map(f => f[vvn]).filter(f=> f && f.VesselVoyageNo && f.VesselVoyageNo==vvn)
      let allIsHidden = true
      let noneAreHidden = true
      for(let pc of filteredPcs) {
        if(this.hiddenCells.filter(f => _.isEqual(f, pc)).length>0){
          noneAreHidden = false
        } else {
          allIsHidden = false
        }
      }
      if(this.hiddenCells.length==0){
        noneAreHidden = true
        allIsHidden = false
      }
      return noneAreHidden ? false : allIsHidden
    },
    removeAllPortcallMarks(vvn){
      if(!vvn) {
        return false
      }
      const pcs = this.schedule;
      const filteredPcs = pcs.map(f => f[vvn]).filter(f=> f.VesselVoyageNo==vvn)
      for(let pc of filteredPcs) {
        if(this.hiddenCells.find(f => _.isEqual(pc, f))){
          this.hiddenCells = this.hiddenCells.filter(f => !_.isEqual(pc, f))
        }
      }
    },
    markAllPortcalls(vvn){
      if(!vvn) {
        return false
      }
      const pcs = this.schedule;
      const filteredPcs = pcs.map(f => f[vvn]).filter(f=> f.VesselVoyageNo==vvn)
      for(let pc of filteredPcs) {
        this.hiddenCells.push(pc)
      }
    },
    toggleGhostHorizontal(port){
      if(!port) {
        return false
      }
      const pcs = this.schedule;
      const filteredPc = pcs.find(f=> f.port==port)
      let keys = Object.keys(filteredPc).slice(3)
      const allIsHidden = keys.map( m=> Object.keys(filteredPc[m]).length>0 ?
                          this.hiddenCells.includes(filteredPc[m]) : undefined).filter(f => f!=undefined).filter(f=> f==false).length==0
      if(allIsHidden){
        for(let key of keys){
          let pc = filteredPc[key]
          this.hiddenCells = this.hiddenCells.filter(f => f!=pc)
        }
      } else {
        for(let key of keys){
          let pc = filteredPc[key]
          if(!this.hiddenCells.includes(pc)) {
            this.hiddenCells.push(pc)
          }
        }
      }
    },
    clickHeader(item){
      const vvn = item.value.VesselVoyageNo
      if(this.allPortcallsMarkedHidden(vvn)){
        this.removeAllPortcallMarks(vvn)
      }else {
        this.markAllPortcalls(vvn)
      }
    },
    clickCell(item, index){
      if(index==='port') {
        return
      }
      const found = this.hiddenCells.find(i => _.isEqual(i,item))
      if(found) {
        this.hiddenCells = this.hiddenCells.filter(f => !_.isEqual(f, found))
      } else {
        this.hiddenCells.push(item)
      }

    },
    async init() {
      this.$store.dispatch("showLoader")
      if(!this.$store.state.trades || this.$store.state.trades.length==0){
        await this.$store.dispatch("getTrades")
      }
      if(!this.$route.params.id){
          this.$router.push({ name: this.$route.name, params: { id: this.$store.state.trades[0].TradeCode } })
        }
      if(!this.tradeCode) {
        this.tradeCode = this.$store.state.trades[0].TradeCode
      }
      return Promise.all([
        this.$store.dispatch("getSchedulesByTrade", { trade: this.$route.params.id }),
        this.$store.dispatch("getRotation", { id: this.$route.params.id }),
      ]).then( () => {
        this.$store.dispatch("hideLoader")
      }).catch((e) => {
        if(e.response && e.response.data.message.err.originalError)
          this.$router.push({name: 'Error', query: {error: e.response.data.message.err.originalError.message}})
        else
          this.$store.dispatch('showError', e.message)
      })
    },

    async generateAndSavePDF() {
      this.fileUploading = true
      const schedule = this.$route.params.id
      const html = `<html><head>`+

        `<link rel="preconnect" href="https://fonts.googleapis.com">`+
        `<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>`+
        `<link href="https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap" rel="stylesheet">`+

        `<style>`+
        'th {font-size: 0.75rem; height: 32px; text-align: left; font-weight: normal;}'+
        '.header {margin-left: 40px; padding: 10px; padding-top: 40px; width: 100%; height: 50px; display: flex; flex-direction: row;}'+
        '.titlerow {display: flex; margin: auto; height: 100%;}'+
        '.title {width: 100%; display: flex; flex-direction: column; justify-content: space-between;}'+
        '.text {display: flex; margin: auto;}'+
        'img {height: 50px; display: inline-block;}'+
        `</style>`+
        //`<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/vuetify/dist/vuetify.min.css" >`+
        `</head><body><div class="header"><img src="${urls.IMG}/g2ocean.png">`+
        `<div style="font-family: Arial, Helvetica, sans-serif; padding-left: 40px;" class="titlerow"><div class="title"><span class="text" style="font-weight: bold;">Sailing Schedule</span>` +
        `<span class="text">${schedule}</span></div></div>`+
        `<div><span class="text" style="color: grey; font-size: 12px; font-family: Arial, Helvetica, sans-serif; padding-right: 20px; margin-top:30px; margin-right: 80px;">${this.todaysDate} </span></div> ` +
        `</div>${this.$refs['exportTable'].$el.innerHTML}</body></html>`

      const obj = { data: html }
      const url = `${urls['FILES']}/pdf`
      this.fileUploadPercentage = 0
      const blob = await Vue.axios.post(url, obj , {
          headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt')
          },
          responseType: 'blob',
          onUploadProgress: (e) => {
            if(e.type=='progress'){
              const rawPercentage = e.loaded / e.total * 100
              this.fileUploadPercentage = rawPercentage.toFixed(2)
              if(this.fileUploadPercentage == 100) {
                this.fileUploadIndeterminate = true
              }
            }
          }
      }).catch(() => {
        this.snackMessage = 'Failed to generate pdf'
        this.snackbar = true
        this.fileUploading = false
        this.fileUploadIndeterminate = false
      })

      if(!blob) {
        return
      }

      // upload port rotation
      const ports = this.schedule.map(m => m.port)
      const blobdata = new Blob([ JSON.stringify(ports)], {type: 'text/plain'})
      this.fileUploadPercentage = 0
      const rotationUrl = `${urls.FILES}/rotations/${schedule}`
      const rotationFormdata = new FormData()
      rotationFormdata.append('file', blobdata, `${schedule}`)
      this.fileUploadIndeterminate = false
      await Vue.axios.post(rotationUrl, rotationFormdata , {
        headers: {
          'Authorization': 'Bearer '+localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (e) => {
          if(e.type=='progress'){
              const rawPercentage = e.loaded / e.total * 100
              this.fileUploadPercentage = rawPercentage.toFixed(2)
              if(this.fileUploadPercentage == 100) {
                if(this.fileUploading){
                  this.fileUploadIndeterminate = true
                }
              }
            }
          }
      }).catch(() => {
        this.snackMessage = 'Failed to store schedule'
        this.snackbar = true
        this.fileUploadIndeterminate = false
      })
      await this.$store.dispatch("getRotation", { id: this.$route.params.id }),



      // upload pdf

      this.fileUploadPercentage = 0
      const ulUrl = `${urls.FILES}/upload/${schedule}`
      const formdata = new FormData()
      formdata.append('file', blob.data, this.pdfArchiveFilename)
      this.fileUploadIndeterminate = false
      await Vue.axios.post(ulUrl, formdata , {
        headers: {
          'Authorization': 'Bearer '+localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (e) => {
          if(e.type=='progress'){
              const rawPercentage = e.loaded / e.total * 100
              this.fileUploadPercentage = rawPercentage.toFixed(2)
              if(this.fileUploadPercentage == 100) {
                if(this.fileUploading){
                  this.fileUploadIndeterminate = true
                }
              }
            }
          }
      }).catch(() => {
        this.snackMessage = 'Failed to upload pdf to archive'
        this.snackbar = true
        this.fileUploadIndeterminate = false
      })


      const fileURL = window.URL.createObjectURL(blob.data);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `sailingSchedule_${this.$route.params.id}.pdf`);
      fileLink.click();
      window.URL.revokeObjectURL(fileURL);

      this.fileUploadIndeterminate = false
      this.fileUploading = false
    },
    getDateFormat(date) {
      new Date(date).toLocaleDateString("en-US", {month: 'short', day: 'numeric'})
    },
    dragReorder ({oldIndex, newIndex}) {
      const movedItem = this.schedule.splice(oldIndex, 1)[0]
      this.schedule.splice(newIndex, 0, movedItem)
    },
  },

}

</script>

<style lang="scss">

html {
  overflow: hidden !important;
} 
.trade-select {
  font-size: 14px;
  background-color: white !important;
}
.vessel-chips {
  color: white !important;
  background-color: rgb(64, 132, 129);
}
.first-column {
  width: 150px !important;
}
.last-column {
  width: 100vw;
}
.middle-columns {
  width: 100px !important;
}
.empty-column {
  width: 50px !important;
  text-align: center;
  padding: 0px !important;
}
.table-cells {
  white-space: pre;
  padding: 0px !important;
  height: 30px !important;
  font-size: 12px !important;
}
.first-headers {
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
  font-size: 0.75rem;
  text-align: left;
  white-space: pre;
  height: 60px;
  padding-right: 15px !important;
  padding-left: 0 !important;
  vertical-align: middle;
  font-weight: 700;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0,0.12);
  border-left: 0;
  border-right: 0;
}
.vessel-headers {
  color: rgba(0, 0, 0, 0.6);
  user-select: none;
  font-size: 0.75rem;
  text-align: left;
  white-space: pre;
  height: 60px;
  padding-right: 15px !important;
  padding-left: 0 !important;
  vertical-align: middle;
  font-weight: 700;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0,0.12);
  border-left: 0;
  border-right: 0;
  cursor: pointer;
}

</style>|