import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import config from '../../config'
import { base64StringToBlob } from 'blob-util';
const urls = require('../../config/urls.js')

Vue.use(Vuex)
Vue.use(VueAxios, axios)

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error && error.response && error.response.status === 401) {
        console.log('Triggering login from 401 interceptor', error.response.data)
        localStorage.setItem('callback', window.location.href)
        window.location.href = urls.LOGIN
        return Promise.reject(error)
    } else {
        // Vue.$store.dispatch('showError', 'Error while booting application, could not load all data. \n', error)
        return Promise.reject(error)
    }
})

axios.defaults.withCredentials = true;

//to handle state
const state = {
    loading: true,
    pdfloading: false,
    error: '',
    token: null,
    headers: [],
    schedules: [],
    itinerarys: [],
    customers: [],
    comments: [],
    trades: [],
    historical: [],
    files: [],
    loadedRotation: [],
    download: null,
    drawer: false,
    jwt: '',
    inLogin: false,
    pdf_zip_loading: false,
    updatePDF: null,
    redrawTable: null,
    scheduleRequestInterval: null,
}

//to handle state
const getters = {}

//to handle actions
const actions = {
    showLoader({ commit }) {
        return commit('SET_LOADING', true)
    },
    showError({ commit }, msg) {
        return commit('SET_ERROR', msg)
    },
    hideError({ commit }) {
        return commit('SET_ERROR', '')
    },
    hideLoader({ commit }) {
        return commit('SET_LOADING', false)
    },
    setPDFLoading({ commit }) {
        return commit('SET_PDF_LOADING', true)
    },
    setPDFLoadingDone({ commit }) {
        return commit('SET_PDF_LOADING', false)
    },
    setUser({ commit }, { user }) {
        if (user) {
            Vue.$cookies.set('user', user);
        } else {
            Vue.$cookies.remove('user')
        }
        return commit('SET_USER', user)
    },
    setUpdatePDFList({ commit }) {
        return commit('SET_UpdatePDF', new Date())
    },
    redrawTable({ commit }) {
        return commit('SET_REDRAW_TABLE', new Date())
    },
    showDrawer({ commit }) {
        return commit('SHOW_DRAWER')
    },
    hideDrawer({ commit }) {
        return commit('HIDE_DRAWER')
    },
    getVessels({ commit }) {
        return Vue.axios.get(`${config.getUrls()['VESSELS']}`, state.headers)
            .then(response => {
                return commit('SET_VESSELS', response.data)
            })
    },
    getTrades({ commit }) {
        return Vue.axios.get(`${config.getUrls()['SCHEDULES']}/trades`, state.headers)
            .then(response => {
                return commit('SET_TRADES', response.data)
            })
    },
    getHistorical({ commit }) {
        return Vue.axios.get(`${config.getUrls()['SCHEDULES']}/historical`, state.headers)
            .then(response => {
                return commit('SET_HISTORICAL', response.data)
            })
    },
    getSchedules({ commit }) {
        if(!this.scheduleRequestInterval) {
                this.scheduleRequestInterval = setInterval(() => {
                     console.log(`${new Date().toLocaleString("en-uk") }: reloading schedules`)
                     this.dispatch('getSchedules')
                }, 60000); //will update every 60 seconds
        }

        return Vue.axios.get(`${config.getUrls()['SCHEDULES']}`, state.headers)
            .then(response => {
                return commit('SET_SCHEDULES', response.data)
            })
    },    
    getSchedulesByTrade({ commit }, { trade }) {
        return Vue.axios.get(`${config.getUrls()['SCHEDULES']}/${trade}`, state.headers)
            .then(response => {
                return commit('SET_SCHEDULES', response.data)
            })
    },
    getFiles({ commit }, { id }) {
        console.log('Redaing files for ', id)
        return Vue.axios.get(`${config.getUrls()['FILES']}/${id}`, state.headers)
            .then(response => {                
                return commit('SET_FILES', response.data)
            }).catch(() => {                
                return commit('SET_FILES', [])
            })
    },
    getRotation({ commit }, { id }) {
        return Vue.axios.get(`${config.getUrls()['FILES']}/rotations/${id}`, state.headers)
        .then(response => {
                return commit('SET_ROTATIONFILES', response.data)
            }).catch(() => {
                return commit('SET_ROTATIONFILES', [])
            })
    },
    getMock() {
        return state.mockData
    },  
    getCustomers({ commit }) {
        return Vue.axios.get(`${config.getUrls()['CUSTOMERS']}`, state.headers)
            .then(response => {
                return commit('SET_CUSTOMERS', response.data)
            })
    },
    getItinerarys({ commit }, { customer }) {
        commit('SET_ITINERARYS', [])
        return Vue.axios.get(`${config.getUrls()['CUSTOMERS']}/itinerarys/${customer.replace('/', '%2F')}`, state.headers)
            .then(response => {
                return commit('SET_ITINERARYS', response.data)
            })
    },
    getComments({ commit }, { customer }) {
        return Vue.axios.get(`${config.getUrls()['COMMENTS']}/${customer.replace('/', '%2F')}`, state.headers)
            .then(response => {
                return commit('SET_COMMENTS', response.data)
            })
    },
    createComment({ commit }, { customer, trade, comment }) {
        return Vue.axios.post(`${config.getUrls()['COMMENTS']}/${customer.replace('/', '%2F')}/${trade}`, {'Comment': comment}, state.headers)
        .then(() => {
            return commit('NOTHING')
        })
    },
    updateComment({ commit }, { customer, trade, comment, id}) {
        return Vue.axios.put(
            `${config.getUrls()['COMMENTS']}/${customer.replace('/', '%2F')}/${trade}/${id}`,
            {'Comment': comment},
            state.headers
        )
        .then(() => {
            return commit('NOTHING')
        })
    },
    clearItinerarys({ commit }) {
        return commit('SET_ITINERARYS', [])
    },
    resetRotation({ commit }) {
        return commit('SET_ROTATIONFILES', [])
    },
    async getZip({ commit }, { name, selected}) {
        await commit('SET_PDF_ZIP_LOADING', true)
        const url = `${config.getUrls()['ZIP']}/${name}`
        return Vue.axios.post(url, selected, state.headers)
        .then(response => {
            const blob = base64StringToBlob(response.data, 'application/zip');
            var fileURL = window.URL.createObjectURL(blob);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', `${name}.zip`);
            document.body.appendChild(fileLink);
            fileLink.click();
            return commit('SET_PDF_ZIP_LOADING', false)
        })
    },
    async createPDF({ commit }, { html }) {
        await commit('SET_PDF_ZIP_LOADING', true)
        const url = `${config.getUrls()['FILES']}/pdf`
        console.log('Sending body: ', html)
        const blob = await Vue.axios.post(url, { data: html }, {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('jwt')
            },
            responseType: 'blob',
        })
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${name}.zip`);
        fileLink.click();
        window.URL.revokeObjectURL(fileURL);
        return commit('SET_PDF_ZIP_LOADING', false)
    },
    async initHeaders({ commit }) {
        return commit('SET_HEADERS')
    },
}

//to handle mutations
const mutations = {
    NOTHING() {

    },
    SHOW_DRAWER(state) {
        state.drawer = true
    },
    HIDE_DRAWER(state) {
        state.drawer = false
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    SET_PDF_LOADING(state, loading) {
        state.pdfloading = loading
    },
    SET_PDF_ZIP_LOADING(state, loading) {
        state.pdf_zip_loading = loading
    },
    SET_USER(state, user) {
        state.handler = user
    },
    SET_ERROR(state, msg) {
        state.error = msg
    },

    SET_HEADERS(state) {
        let bearer = localStorage.getItem('jwt')
        state.headers = {
            headers: {
                'Cache-Control': ' no-cache',
                'Authorization': `Bearer ${bearer}`,  // send the access token through the 'Authorization' header
                'withCredentials': true,
            }
        }
    },
    SET_VESSELS(state, vessels) {
        state.vessels = vessels
    },    
    SET_SCHEDULES(state, data) {
        state.schedules = data
    },
    SET_CUSTOMERS(state, data) {
        state.customers = data
    },    
    SET_ITINERARYS(state, data) {
        state.itinerarys = data
    },
    SET_COMMENTS(state, data) {
        state.comments = data
    },
    SET_TRADES(state, data) {
        state.trades = data
    },
    SET_HISTORICAL(state, data) {
        state.historical = data
    },
    SET_FILES(state, files) {
        state.files = files
    },
    SET_ROTATIONFILES(state, files) {
        state.loadedRotation = files
    },
    SET_UpdatePDF(state, date){
        state.updatePDF = date
    },
    SET_REDRAW_TABLE(state, date){
        state.redrawTable = date
    }
}


//export store module
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})
/** we have just created a boiler plate for our vuex store module**/
