<template>
  <v-container fluid>
    <v-row class="pt-2">
      <v-col>
        <files-dropdown
          v-model="selected"
          :files="files"
          :ownerColor="ownerColor"
          @input="handleClick"  />
      </v-col>
      </v-row>
    <v-row>
      <v-col>
        <v-card v-if="previewable || files.length==0" loading="$store.state.pdfloading" elevation="0" height="60vh" tile class="striped">
          <iframe
            :class="{'hide': !pdfLoaded}"
            ref="iframe_"
            frameborder="0"
            width="100%"
            style="height: 100%;"
            :src="openFile"
            @load="signalpdfLoaded()"
          >
          </iframe>
        </v-card>
        <v-card  v-if="!previewable && files.length>0" tile class="striped d-flex flex-column justify-center" height="60vh">
          <v-card-text>
            <v-row>
              <v-col>

                <v-alert
                  border="left"
                  type="warning"
                  colored-border
                  style="background-color: #FFFFFF99"
                >
                  No preview available for this file extention
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import FilesDropdown from "../components/FilesDropdown";
const urls = require('../../config/urls')
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

export default {
  name: "FilesDialogContent",
  props: ["id", "ownerColor"],
  data: () => ({
    loaded: false,
    openFile: null,
    pdfLoaded: false,
    fileUploading: false,
    fileUploadIndeterminate: false,
    fileUploadPercentage: 0,
    hideIframe: false,
    snackbar: false,
    selected: '',
    previewable: false,
    callback: null,
    uploadedFile: null,
  }),
  components: {
    FilesDropdown
  },
  watch: {
    '$route.params.id': function () {
      this.init()
    }
  },
  mounted: function () {
    this.loaded = false;
    this.init().then(() => {
      this.loaded = true
    });
  },
  computed: {
    displayPDF() {
      return (this.openFile && this.pdfLoaded)
    },
    files() {
      let files = this.$store.state.files
      return files.sort((a, b) => b.localeCompare(a))
    }
  },
  methods: {
    canPreviewThis(item){
      return item.endsWith('.pdf')
    },
    signalpdfLoaded() {
      this.pdfLoaded = true
      this.$store.dispatch('setPDFLoadingDone')
      this.$store.dispatch("hideLoader")
    },
    async handleClick(item) {
      if(!item || !this.canPreviewThis(item)) {
        this.previewable = false
        return
      }
      this.previewable = true
      this.selected = item
      this.openFile = null
      this.$store.dispatch('setPDFLoading')
      this.pdfLoaded = false
      const self = this

      const url = `${urls.FILES}/${this.id}/${this.selected}#view=FitH`
      const file = await Vue.axios.get(url, {
          headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt')
          },
          responseType: 'blob',
      })

      this.openFile = window.URL.createObjectURL(file.data)

      if(this.isiOS) {
        return
      }
      if(this.callback) {
          clearTimeout(this.callback);
      }
      this.callback = setTimeout(() => {
        if(!self.pdfLoaded){
          self.openFile = null
          self.snackbar = true
          this.selected = null
          self.$store.dispatch('setPDFLoadingDone')
        }
      }, 14000);
    },
    init() {
      if(this.openFile) {
        this.openFile = null
      }
      this.$store.dispatch("showLoader")
      return Promise.all([
        this.$store.dispatch("getFiles", {id: this.id}),
      ]).then( () => {
        this.$store.dispatch("hideLoader")
        if(this.files.length>0) {
          this.handleClick(this.files[0])
        }
      }).catch((e) => {
        if(e.response && e.response.data.message.err.originalError)
          this.$router.push({name: 'Error', query: {error: e.response.data.message.err.originalError.message}})
        else
          this.$store.dispatch('showError', e.message)
      })
    },
    onFileChanged(e) {
      this.uploadedFile = e.target.files[0]
      this.submit()
    },
    async submit() {
      // If there aren't any files to be uploaded throw error
      if (!this.uploadedFile) {
        this.$store.dispatch("addNotification", {
          message: "There are no files to upload",
          colour: "error"
        });
      } else {
        this.fileUploadPercentage = 0
        this.fileUploading = true
        this.fileUploadIndeterminate = false
        const url = `${urls.FILES}/upload/${this.id}`
        var data = new FormData()
        data.append('file', this.uploadedFile)

        await Vue.axios.post(url, data , {
            headers: {
                'Authorization': 'Bearer '+localStorage.getItem('jwt'),
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (e) => {
              if(e.type=='progress'){
                const rawPercentage = e.loaded / e.total * 100
                this.fileUploadPercentage = rawPercentage.toFixed(2)
                if(this.fileUploadPercentage == 100) {
                  setTimeout(() => {
                    if(this.fileUploading){
                      this.fileUploading = false
                      this.fileUploadIndeterminate = true
                    }
                  }, 800)
                }
              }
            }
        })
        this.fileUploadIndeterminate = false
        this.fileUploading = false
        this.init()
        this.handleClick(this.uploadedFile.name)
      }
    }
  }
}
</script>

<style scoped>
.hide {
  visibility: hidden;
}
.v-image {
  filter: drop-shadow(#1976d2 2px 2px 2px);
}
.drop {
  filter: drop-shadow(3px 3px 2px black);
}
.chosen_vessel {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.striped {

  background: repeating-linear-gradient(
    45deg,
    #c0c0c0,
    #c0c0c0 5px,
    #b0b0b0 5px,
    #b0b0b0 10px
  );
}

.hide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
</style>