<template>
  <v-container :fluid="this.$vuetify.breakpoint.lgAndDown" :class="`px-${getPaddingX} py-8`" style="height: 100%;">
    <v-row class="px-3 d-flex justify-space-between align-center">
      <v-col lg="6" sm="12" class="px-0">
        <v-autocomplete
            v-model="port"
            label="Port"
            outlined
            hide-details
            dense
            clearable
            :color="getOwnerColor"
            :items="portList"
            item-text="PortName"
            item-value="PortName"
            class="dropdown"
            @change="updateUrl"
        >
        </v-autocomplete>
      </v-col>
      
      <v-col lg="6" sm="12" class="text-right pa-0 mt-3">
        <vue-excel-xlsx
            :data="excelList"
            :columns="excelColumns"
            :file-name="`Schedule port ${this.port}`"
            :file-type="'xlsx'"
            :sheet-name="`Port ${this.port}`"
            >
            <span class="export_btn_title">Generate EXCEL</span>
            <v-btn size="30" variant="text" icon color="green"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
        </vue-excel-xlsx>

          <v-btn :disabled="fileUploading" style="font-size: 11px;" elevation="0" @click="generatePDF">
          <span>Generate pdf</span>
          <v-icon v-if="!fileUploading" :color="getOwnerColor" class="ml-2">mdi-file-pdf-box</v-icon>
          <v-progress-circular
              v-if="fileUploading"
              class="ml-2"
              :value="fileUploadPercentage"
              :indeterminate="fileUploadIndeterminate"
              :width="1"
              :size="18"
              :color="getOwnerColor"
          >
            {{ percentOrNothing }}
          </v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <v-progress-circular
            v-if="loading"
            indeterminate
            :color="getOwnerColor"
            class="mt-8"
          ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!loading" class="pt-0">
        <v-card flat class="rounded-lg pa-4" transition="fade-transition" style="overflow:auto;">
          <v-data-table
            :items="getSchedule"
            :headers="headers"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            class="port-table"
          >
          <template v-slot:[`item.VesselName`]="{ item }">
            {{ toTitleCase(item.VesselName) }}
          </template>
          <template v-slot:[`item.ArrivalDate`]="{ item }">
            {{ getISO8601dateFromDate(item.ArrivalDate) }}
          </template>
          <template v-slot:[`item.BerthedDate`]="{ item }">
            {{ getISO8601dateFromDate(item.BerthedDate) }}
          </template>
          <template v-slot:[`item.DepartureDate`]="{ item }">
            {{ getISO8601dateFromDate(item.DepartureDate) }}
          </template>
          <template v-slot:[`item.ReasonForCall`]="{ item }">
            {{ toTitleCase(item.ReasonForCall) }}
          </template>
          <template v-slot:[`item.Quantity`]="{ item }">
            {{ item.Quantity > 0 ? Math.round(item.Quantity) + ' MT' : '-' }}
           </template>
        </v-data-table>
        </v-card>
        </v-col>
      </v-row>
      <v-row v-if="getSchedule && getSchedule[0] && getSchedule[0].APIRequestTime">
        <span style="font-size: 11px;">{{getSchedule[0].envName }} | GUI: v{{ require('../../package.json').version }}&nbsp;| API: v{{getSchedule[0].APIv }} | Data loaded: {{ new Date(getSchedule[0].APIRequestTime).toLocaleString("en-uk") }}</span>
      </v-row>

      <v-card flat class="pa-4 rounded-lg d-none" style="width: 100%;">
        <pdf-table
          :schedule="getSchedule"
          :headers="headers"
          :isTrade="false"
          ref="exportTable"
          />
      </v-card>

  </v-container>
</template>

<script>
import { toTitleCase, getOwnerColor, getISO8601dateFromDate } from "../components/Tools"
import PdfTable from "../components/PdfTable.vue"
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueExcelXlsx from '@/components/VueExcelXlsx'
Vue.use(VueAxios, axios)
const urls = require('../../config/urls')
export default {
  name: "SailingScheduleByPort",
  props: [],
  components: { PdfTable, VueExcelXlsx } ,
  data: () => ({
    loading: false,
    port: null,
    g2img: '',
    fileUploading: false,
    fileUploadIndeterminate: false,
    fileUploadPercentage: 0,
  }),
  mounted: function () {
    this.loading = true
    this.init().then(() => {
      this.loading = false
      this.g2img = `${urls['IMG']}/g2ocean.png`
      if(this.$route.params.id || this.$route.params.id == 'undefined') {
        this.port = this.$route.params.id
      }
    })
  },
  computed: {    
    getOwnerColor,
    getPaddingX() {
      return this.$vuetify.breakpoint.smAndDown ? 5 : 15
    },
    percentOrNothing(){
      return this.fileUploadIndeterminate || this.fileUploadPercentage<1 ? '' : this.fileUploadPercentage
    },
    portList() {
      if(!this.$store.state.schedules) return []
      let ports = [... new Set(this.$store.state.schedules.map(f => f.PortName))]
      return ports.sort((a, b) => a?.localeCompare(b))
    },
    getSchedule() {
      let data = this.$store.state.schedules.filter(f => f.PortName == this.port).sort((a, b) => a.ArrivalDate?.localeCompare(b.ArrivalDate))
      return data
    },
    headers() {
      return [
        { text: "Vessel", value: "VesselName", sortable: false, filterable: false, groupable: true},
        { text: "Arrival", value: "ArrivalDate", sortable: false, filterable: false, groupable: true},
        { text: "Berthed", value: "BerthedDate", sortable: false, filterable: false, groupable: true},
        { text: "Departure", value: "DepartureDate", sortable: false, filterable: false, groupable: true},
        { text: "Reason", value: "ReasonForCall", sortable: false, filterable: false, groupable: true},
        { text: "Quantity", value: "Quantity", sortable: false, filterable: false, groupable: true},
      ]
    },
    todaysDate() {
      return new Date().toLocaleString("en-uk").slice(0,-3)
    },
    excelColumns(){
      return [
        {
            label: "Vessel",
            field: "VesselName",
            width: 25,
        },
        {
            label: "Arrival",
            field: "ArrivalDate",
            width: 25,
        },
        {
            label: "Berthed",
            field: "BerthedDate",
            width: 25,
        },
        {
            label: "Departure",
            field: "DepartureDate",
            width: 25,
        },
        {
            label: "Reason",
            field: "ReasonForCall",
            width: 15,
        },
        {
            label: "Quantity",
            field: "Quantity",
            width: 10,
        },
      ]
    },
    excelList() {
      return JSON.parse(JSON.stringify(this.getSchedule)) 
    },
  },
  methods: {
    toTitleCase, getISO8601dateFromDate,
    updateUrl(){
      this.$router.push({ name: this.$route.name, params: { id: this.port } })
    },
    async generatePDF() {
      this.fileUploading = true
      const html = `<html><head>`+
        `<link rel="preconnect" href="https://fonts.googleapis.com">`+
        `<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>`+
        `<link href="https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap" rel="stylesheet">`+

        `<style>`+
        'th {font-size: 0.75rem; height: 32px; text-align: left; font-weight: normal;}'+
        '.header {margin-left: 40px; padding: 10px; padding-top: 40px; width: 100%; height: 50px; display: flex; flex-direction: row;}'+
        '.titlerow {display: flex; margin: auto; height: 100%;}'+
        '.title {width: 100%; display: flex; flex-direction: column; justify-content: space-between;}'+
        '.text {display: flex; margin: auto;}'+
        'img {height: 50px; display: inline-block;}'+
        `</style>`+
        `</head><body><div class="header"><img src="${urls.IMG}/g2ocean.png">`+
        `<div style="font-family: Arial, Helvetica, sans-serif; padding-left: 40px;" class="titlerow"><div class="title"><span class="text" style="font-weight: bold;">Sailing Schedule</span>` +
        `<span class="text">${this.port}</span></div></div>`+
        `<div><span class="text" style="color: grey; font-size: 12px; font-family: Arial, Helvetica, sans-serif; padding-right: 20px; margin-top:30px; margin-right: 80px;">${this.todaysDate} </span></div> ` +
        `</div>${this.$refs['exportTable'].$el.innerHTML}</body></html>`
      
      const obj = { data: html }
      const url = `${urls['FILES']}/pdf`
      this.fileUploadPercentage = 0
      const blob = await Vue.axios.post(url, obj , {
          headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt')
          },
          responseType: 'blob',
          onUploadProgress: (e) => {
            if(e.type=='progress'){
              const rawPercentage = e.loaded / e.total * 100
              this.fileUploadPercentage = rawPercentage.toFixed(2)
              if(this.fileUploadPercentage == 100) {
                this.fileUploadIndeterminate = true
              }
            }
          }
      }).catch(() => {
        this.snackMessage = 'Failed to generate pdf'
        this.snackbar = true
        this.fileUploading = false
        this.fileUploadIndeterminate = false
      })

      if(!blob) {
        return
      }

      const fileURL = window.URL.createObjectURL(blob.data);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `sailingSchedule_${this.$route.params.id}.pdf`);
      fileLink.click();
      window.URL.revokeObjectURL(fileURL);

      this.fileUploading = false
    },
    init() {
      this.$store.dispatch("showLoader")
      if(!this.$route.params.id){
          this.$router.push({ name: this.$route.name, params: { id: this.portList[0] } })
        }
      if(!this.port) {
        this.port = this.portList[0]
      }
      return Promise.all([
        this.$store.dispatch("getSchedules"),
      ]).then( () => {
        this.$store.dispatch("hideLoader")
      }).catch((e) => {
        if(e.response && e.response.data.message.err.originalError)
          this.$router.push({name: 'Error', query: {error: e.response.data.message.err.originalError.message}})
        else
          this.$store.dispatch('showError', e.message)
      })
    }
  },
}

</script>

<style lang="scss">

html {
  overflow: hidden !important;
} 
.port-table td{
  font-size: 12px !important;
  white-space: pre;
  text-align: left;
}
</style>|