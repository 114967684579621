<template>
  <v-container>
    <v-row style="text-align: center; padding-top: 5px;">
      <table :style="`font-family: Lato, sans-serif; width: ${getWidth}%; margin-left: 40px; margin-right: 40px; border-top: 0px solid lightgrey; border-bottom: 1px solid lightgrey;  border-collapse: collapse; margin-top: 20px;`">
        <thead>
          <tr>
            <th v-for="(col, i) in headers.slice(0,-1)"  style="padding-left: 10px; padding-right: 10px; min-width: 80px; font-size: 12px; font-weight: bold; color: rgba(0, 0, 0, 0.6);" :key="i" :class="{'text-left': true}">
              <span style="white-space: pre;">{{toTitleCase(col.text)}}</span>
            </th>
            <th v-for="(col, i) in headers.slice(-1)"  :style="`padding-left: 10px; width: ${getColumnWidth}vw; padding-right: 10px; min-width: 80px; font-size: 12px; font-weight: bold; color: rgba(0, 0, 0, 0.6);`" :key="i + 'A'" :class="{'text-left': true}">
              <span style="white-space: pre;">{{toTitleCase(col.text)}}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, i) in schedule" :key="i" >
            <td
              v-for="(col, j) in headers"
              :key="j"
              :style="`padding-left: 10px; padding-right: 10px; white-space: pre; height: 25px; border-top: ${getBorder(row, schedule[i-1], false)}`"
              :class="{'text-left': true}"
            >
              <span v-if="isTrade && isPort(col) && !isGhost(row[col.value])" style="font-size: 11px; height: 100%;">{{ toTitleCase(row[col.value]) }}</span>
              <span v-if="isTrade && !isPort(col) && isVisible(row, col)" class="px-1" style="font-size: 11px; height: 100%;">{{ printableDate(row, col) }}</span>
              
              <span v-if="!isTrade && (col.text==='Vessel' | col.text==='Port' | col.text==='Reason' | col.text==='Operator' | col.text === 'Agent'| col.text === 'Agent contact'| col.text === 'Voyage #'| col.text === 'Trade Code')" style="font-size: 11px; height: 100%; max-width: 150px; white-space: pre-line;">{{ row[col.value] ? toTitleCase(row[col.value]) : '-'}}</span>
              <span v-if="!isTrade && (col.text==='Arrival' | col.text==='Berthed' | col.text==='Departure')" style="font-size: 11px; height: 100%;">{{ getISO8601dateFromDate(row[col.value]) }}</span>
              <span v-if="!isTrade && col.text==='Quantity'" style="font-size: 11px; height: 100%;">{{ row[col.value] > 0 ? Math.round(row[col.value]) + ' MT' : '-'}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </v-row>

    <v-row style="border-top: 1px solid light; text-align: center; padding-top: 20px;">
      <h5 style="font-family: Arial, Helvetica, sans-serif; color: grey; font-weight: normal;">All dates, vessels, and port calls are subject to change without notice</h5>
    </v-row>

    <v-row style="text-align: center;">
      <h5 style="font-family: Arial, Helvetica, sans-serif; color: rgb(64, 132, 129); font-weight: bold;">www.g2ocean.com</h5>
    </v-row>

  </v-container>
</template>

<script>
import { getISO8601dateFromDate, toTitleCase } from "./Tools";
const urls = require('../../config/urls')

export default {
  name: "PdfTable",
  props: ["schedule", "headers", "isHidden", "arrival", "departure", "isTrade"],
  data: () => ({
  }),
  watch: {
    '$store.state.updatePDF': function() {
      this.$forceUpdate()
    },
  },
  computed: {
    getWidth() {
      return !this.isTrade ? 93 : ""
    },
    getColumnWidth() {
      return this.isTrade ? 100 : ""
    }
  }, 
  methods: {
    toTitleCase, getISO8601dateFromDate,
    getBorder(row, prev, isLast) {
      return row?.port == 'ghost' && ((prev && prev.port == 'ghost') || isLast) ? '0px solid lightgrey;' : '1px solid lightgrey;'
    },
    isGhost(portName) {
      return portName == 'ghost'
    },
    isVisible(row, col) {
      let value = row[col.value.VesselVoyageNo]
      let goodValue = value && Object.keys(value).length>0
      if(!goodValue){
        return false
      }
      return !this.isHidden(value)
    },
    isPort(col) {
      return col.value==='port'
    },
    printableDate(row, col) {
      if(!col || !col.value || !col.value.VesselVoyageNo) {
        return ''
      }
      let actualColumn = col.value.VesselVoyageNo
      let value = row[actualColumn]
      let arrivalDate = new Date(value['ArrivalDate']).toLocaleDateString("en-US", {month: 'short', day: 'numeric'}) 
      let departureDate = new Date(value['DepartureDate']).toLocaleDateString("en-US", {month: 'short', day: 'numeric'}) 
      if (this.arrival && this.departure) {
        return value && value['ArrivalDate'] && value['DepartureDate'] ? arrivalDate + "\n" + departureDate : '-'
      } else if (this.arrival && !this.departure) {
        return value && value['ArrivalDate'] ? arrivalDate : '-'
      } else if (!this.arrival && this.departure) {
        return value && value['DepartureDate'] ? departureDate : '-'
      }
    },
    getImage() {
      return `${urls.IMG}/g2ocean.png`
    },
    todaysDate() {
      return new Date().toLocaleString("en-uk").slice(0,-3)
    }
  },
}
</script>

<style lang="scss">
.narrow {
  min-width:140px;
}
.wide {
  width: 100% !important;
}
</style>