<template>
  <v-container :fluid="this.$vuetify.breakpoint.lgAndDown" :class="`px-${getPaddingX} py-8 align-items-start`" style="height: 100%;">
    <v-row class="d-flex justify-space-between align-center noprint">
      <v-col lg="8" sm="12" class="pr-3">
        <v-autocomplete
            v-model="shipper"
            label="Shipper"
            outlined
            hide-details
            dense
            clearable
            :color="getOwnerColor"
            :items="shipperList"
            item-text="CustomerName"
            item-value="CustomerName"
            class="dropdown noprint"
            @change="getItinerarys"
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="3" sm="2" lg="1">
          <v-checkbox
          v-model="laycan"
          hide-details
          dense
          :color="getOwnerColor"
          label="LayCan">
        </v-checkbox>
      </v-col>

      <v-col class="text-right mt-4 no-print">
        <v-btn
            v-if="!loading"
            elevation="0"
            x-small
            color="#c3dad9"
            @click="print" 
            >Print table(s)
          </v-btn>
      </v-col>
    </v-row>                   

    <v-row>
      <v-col class="pa-0">
        <v-progress-circular
            v-if="loading"
            indeterminate
            :color="getOwnerColor"
            class="mt-8"
          ></v-progress-circular>
      </v-col>
    </v-row>

    <div id="isPrinted" class="printing">

      <v-row class="only-print text-left inline-block">
        <v-col class="d-flex align-end">
          <img :src="g2img" class="mr-6">
          <h2> {{ shipper }}</h2>
        </v-col>
      </v-row>

      <v-row v-for="trade, index in tradeList" :key="index">
        <v-col v-if="!loading" cols="12" class="pt-0 col-padding" :class="{'noprint': tradeCodes.includes(trade)}">
          <v-card flat class="rounded-lg px-6 pb-4 pt-1" transition="fade-transition" style="overflow:auto;">
            <v-overlay
              absolute
              :value="tradeCodes.includes(trade)"
              class="noprint"
            >
              <v-btn outlined @click="hideShow(trade)">
                <v-icon small class="pa-1">mdi-printer-outline</v-icon>
                Include</v-btn>
            </v-overlay>
            <v-col class="px-0">
              <v-row>
                <v-col cols="auto">
                  <h3 class="text-left py-2"> {{ trade }}</h3>
                </v-col>
                <v-col class="noprint d-flex justify-start">
                  <v-btn
                    elevation="0"
                    x-small
                    class="mt-2"
                    @click="hideShow(trade)"
                    color="#c3dad9"
                  >
                    <v-icon x-small class="pa-1">mdi-printer-off-outline</v-icon>
                    Exclude</v-btn>
                </v-col>
              </v-row>

              <v-data-table
                :items="renderListByTradecode(trade)"
                :headers="headers(trade)"
                class="trade-table"
                hide-default-footer
                disable-pagination
                mobile-breakpoint="0"
              ></v-data-table>
            </v-col>

          <v-col class="pa-0 noprint">
            <v-text-field
              v-if="shipper!=''"
              v-model="comment[trade]"
              label="Add comment"
              :color="getOwnerColor"
              filled
              hide-details
              >
            </v-text-field>
          </v-col>
          <v-col v-if="comment[trade]" class="only-print text-left" style="font-size: 11px;">
            <span style="font-weight: bold;">Comment: </span>
            <span> {{ comment[trade] }}</span>
          </v-col>
          
          <v-col class="pa-0 mt-1 text-right noprint">
            <v-btn
              elevation="0"
              x-small
              :color="getOwnerColor"
              class="white--text"
              @click="saveComment(shipper, trade, comment[trade])"
              :loading = "savingComment"
              >Save
            </v-btn>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </div>
  </v-container>
</template>

<script>
import { toTitleCase, getOwnerColor } from "../components/Tools"
import _ from 'lodash'
const urls = require('../../config/urls')

export default {
  name: "Shipper",
  props: [],
  components: { },
  data: () => ({
    shipper: '',
    tradeCodes: [],
    comment: {},
    laycan: true,
    savingComment: false,
    g2img: ''
  }),
  mounted: function () {
    this.init().then(() => {
      if(this.$route.params.id && this.$route.params.id != 'undefined') {
        this.shipper = this.$route.params.id
      }
      if(!this.shipper) {
        this.shipper = this.shipperList[0]['CustomerName']
      }
      this.getItinerarys(this.shipper)
      this.g2img = `${urls['IMG']}/g2ocean.png`
    })
  },
  updated() {
    if (this.$refs.calendar) {
      this.title = this.$refs.calendar.title
    }
  },
  watch: {
    'shipper': function(shipper) {
      if(!shipper) {
        this.$router.push({ name: this.$route.name, params: { id: null } })
      }
      if(this.$route.params.id != shipper) [
        this.$router.push({ name: this.$route.name, params: { id: shipper.replace('/', '%2F') } })
      ]
    },
  },
  computed: {
    getOwnerColor,
    getPaddingX() {
      return this.$vuetify.breakpoint.smAndDown ? 5 : 15
    },
    loading(){
      return this.$store.state.loading
    },
    shipperList() {
      if(!this.$store.state.customers) return []
      return this.$store.state.customers
    },
    tradeList() {
      if(!this.$store.state.itinerarys) return []
      let trades = [... new Set(this.$store.state.itinerarys.map(f => f.TradeCode))]
      return trades.sort((a, b) => a.localeCompare(b))
    }
  },
  methods: {
    toTitleCase,
    hideShow(trade){
      if(this.tradeCodes.includes(trade)){
        this.tradeCodes = this.tradeCodes.filter(f=> f!=trade)
      } else {
        this.tradeCodes.push(trade)
      }
    },
    print() {
      window.print(document.getElementById('isPrinted'))
      },
      async saveComment(customer, trade, comment) {
      this.savingComment = true
      let id = null
      for(let comment of this.$store.state.comments) {
        if(comment.CustomerName == customer && comment.TradeCode == trade){
          id=comment.id
        }
      }
      if(id){
        await this.$store.dispatch("updateComment", {customer, trade, comment, id})
      }else {
        await this.$store.dispatch("createComment", {customer, trade, comment})
      }
      await this.$store.dispatch("getComments", {customer: customer}),
      this.savingComment = false
    },
    renderListByTradecode(trade){
      if (!this.shipper) return []
      let data = this.$store.state.itinerarys.
        filter(f => f.TradeCode == trade)
      let vessels = data.map(item => [item.VesselName, item.VoyNo, item.laycanFromLocal, item.laycanToLocal])
      const result = []
      const dateTool = this.getDate
      vessels.forEach(function(vesselArr){
        let [ vessel, voyno, laycan1, laycan2 ] = vesselArr
        if(!result.find(f => f.vessel == vessel && f.voyno == voyno))
        {
          let item = {
            'vessel': vessel,
            'voyno': voyno,
            'laycan': [dateTool(laycan1) + "\n" + dateTool(laycan2)]
          }
          let ports = data.filter(d => d.VesselName == vessel).map(item => [item.PortName, item.ArrivalDate, item.DepartureDate, item.VoyNo])
          ports = _.uniqWith(ports,_.isEqual) // Remove dupes
          ports.forEach(portArr => {
            let [portName, arrivalDate, depDate] = portArr
            item[portName] = dateTool(arrivalDate) + "\n" + dateTool(depDate)
          })
          result.push(item)
        }
      })
      for(let trade of this.tradeList) {
        this.comment[trade] = this.$store.state.comments.find(f => f.TradeCode == trade && f.CustomerName==this.shipper)?.Comment
      }
      return result
    },
    headers(tradeCode) {
      if (!this.shipper) return []
      let data = this.$store.state.itinerarys.
        filter(f => f.TradeCode == tradeCode).
        sort((a,b) => {
          if(a.Activity == 'L' && b.Activity != 'L') return -1
          else return 1
        })

      let ports = [... new Set(data.map(f => f.PortName))]
      let headers = [
        {text: "Vessel", value: "vessel", sortable: false, width: "1%", filterable: false, groupable: true},
        {text: "Voyage no", value: "voyno", sortable: false, width: "1%", filterable: false, groupable: true},
      ]
      if (this.laycan) {
        headers.push({text: "LayCan", value: "laycan", sortable: false, width: "1%", filterable: false, groupable: true})
      }
      let that = this
      ports.forEach(function(port, index) {
        if (index !== (ports.length-1)) {
          headers.push({
          text: that.toTitleCase(port),
          value: port,
          sortable: false,
          width: "1%",
          filterable: false,
          groupable: false
          })
        } else {
          headers.push({
          text: that.toTitleCase(port),
          value: port,
          sortable: false,
          width: "100%",
          filterable: false,
          groupable: false
        })
        }
      })
      return headers
    },
    getDate(date) {
      if (!date) {
        return '-'
      }
      return new Date(date).toLocaleDateString("en-US", {month: 'short', day: 'numeric'})
    },
    async getItinerarys(item){
      if(!item) {
        await this.$store.dispatch("clearItinerarys")
        return
      }
      this.$store.dispatch("showLoader")
      await Promise.all([
        this.$store.dispatch("getItinerarys", {customer: item}),
        this.$store.dispatch("getComments", {customer: item}),
      ])
      this.$store.dispatch("hideLoader")
    },
    init() {
      this.$store.dispatch("showLoader")
      return Promise.all([
        this.$store.dispatch("getCustomers"),
      ]).then( () => {
        this.$store.dispatch("hideLoader")
      }).catch((e) => {
        if(e.response && e.response.data.message.err.originalError)
          this.$router.push({name: 'Error', query: {error: e.response.data.message.err.originalError.message}})
        else
          this.$store.dispatch('showError', e.message)
      })
    }
  }
}

</script>

<style lang="scss">

html {
  overflow: hidden !important;
}
.v-chip {
  margin: 1px 8px 4px 0;
}
.v-slide-group__content {
  padding: 0 !important;
}
.dropdown {
  font-size: 13px;
  background-color: white !important;
}
.trade-table td{
  font-size: 12px !important;
  white-space: pre;
  text-align: left;
}
.trade-table th{
  white-space: nowrap;
  text-align: left;
}
.first-columns {
  width: 100px !important;
}
.last-column {
  width: 100vw !important;
}
.only-print {
  display: none;
  visibility: hidden;
}
.col-padding {
  padding-bottom: 24px;
}
@media print {
  @page {
    size: auto;
    width: 100%;
    height:100vh;
    margin: 0;
    padding: 0;
  }
  .printing {
    width: 100%;
    margin: 0;
    padding: 0; 
  }
  .noprint {
    display: none;
    visibility: hidden;
    padding: 0px;
    margin: 0px;
  }
  .only-print {
    display: block;
    visibility: visible;
  }
  .col-padding {
  padding-bottom: 0px;
}
  img {
    height: 50px;
    display: inline-block;
  }
}
</style>|