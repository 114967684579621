<template>
  <v-container :fluid="this.$vuetify.breakpoint.lgAndDown" :class="`px-${getPaddingX} py-8`" style="height: 100%;">
    <v-row>
      <v-col lg="7" class="d-flex">
        <v-autocomplete
          v-model="selectedAreas"
          :items="getArea"
          label="Area"
          outlined
          multiple
          class="input mr-3"
          clearable
          :color="getOwnerColor"
          hide-details
          dense
          @change="updateView()"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedAreas.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="selectedTrades"
          :items="getTrades"
          label="Trade"
          outlined
          class="input mr-3"
          multiple
          clearable
          :color="getOwnerColor"
          hide-details
          dense
          @change="updateView()"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedTrades.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="selectedPorts"
          :items="getPorts"
          label="Port"
          outlined
          class="input mr-3"
          clearable
          multiple
          :color="getOwnerColor"
          hide-details
          dense
          @change="updateView()"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedPorts.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-model="selectedVessels"
          :items="getVessels"
          label="Vessel"
          outlined
          class="input mr-3"
          clearable
          multiple
          :color="getOwnerColor"
          hide-details
          dense
          @change="updateView()"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedVessels.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
        </v-col>

        <v-col cols="auto" class="pt-4 d-flex">
          <date-range-input
          v-model="dateRange"
          :min="getMinDate"
          :max="getMaxDate"
          :ownerColor="getOwnerColor"
          @change="updateView()">
          </date-range-input>
        </v-col>
      </v-row>
    <v-row>
      <v-col class="pt-0 pb-6">
        <v-progress-circular
            v-if="loading"
            indeterminate
            class="mt-6"
            :color="getOwnerColor"
          ></v-progress-circular>
        <v-card flat fluid class="d-flex pa-4 rounded-lg" v-if="!loading" transition="fade-transition" style="overflow: auto; max-height: calc(100vh - 185px);">
          <v-simple-table fixed-header height="100%" class="tablestyle">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="height: 30px !important;"></th>
                  <th style="height: 30px !important;" class="px-0 pt-3">
                    <div style="display: flex; height: 30px !important;">
                      <div v-for="(pc, index) in dates" :style="`width: ${dayInPixels}px; height: 30px; padding: 2px;`" :key="index">
                        <div v-if="isFirst(getShortDate(pc), index)" style="position: absolute; margin-top: -16px;">{{getMonth(pc)}}</div>
                        <span style="white-space: nowrap;">{{getShortDate(pc)}}</span>
                        <div :style="`min-width: 100%; min-height: 1px; background-color: ${getDayColor(pc)}; padding: 1px;`"></div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody id="dragTable">
                <tr
                  v-for="(item, rowindex) in model"
                    :key="rowindex"
                  >
                    <td class="text-left dense traderow" v-if="item.type == 'Trade'">
                      <v-row>
                        <v-col cols="auto" class="px-2">
                          <toggle-minimized v-model="minimizedTrades" :current="item.value"></toggle-minimized>
                        </v-col>
                        <v-col class="pl-0">
                          {{item.value}}
                        </v-col>
                      </v-row>
                    </td>
                    <td :colspan="dates.length" v-if="item.type == 'Trade'" class="d-flex fill-width dense traderow" :id="rowindex == 0 ? 'measurestick' : null" :ref="rowindex == 0 ? 'measurestick' : null">
                    </td>

                    <td class="text-left pl-10 dense vesselcell" v-if="item.type == 'Vessel' && !tradeHidden(item)">
                      {{item.VesselName}}
                    </td>

                    <td :colspan="dates.length" v-if="item.type == 'Vessel' && !tradeHidden(item)" class="d-flex fill-width dense px-0">
                      <div class="window" style="overflow:hidden; white-space: none; width: 100%; display: flex; position: relative; height: 100%; padding: 0; margin: 0;">
                        <div
                          v-for="(pc, index) in getSegments(item.value)"
                          :key="`b${index}`"
                        >

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="berthpcsegment"
                              :class="{'d-none': checkIfNoArrivalToBerth(pc)}"
                              :style= "`margin-left: ${pc.daysFromFirstToArrival*dayInPixels}px; width: ${pc.duration*dayInPixels}px; background-color: ${getOwnerColorLight};`">
                            </div>
                          </template>
                            <span class="text-left tooltip">
                              <div><b>{{ pc.port }}</b><i> ({{ toTitleCase(pc.vessel) }})</i></div>
                              <div class="tooltip-rows"><b>Arrival: </b>{{ getUTCDateString(pc.arrival) }} </div>
                              <div class="tooltip-rows"><b>Berthed: </b> {{ getUTCDateString(pc.berthed) }} </div>
                              <div class="tooltip-rows"><b>Departure: </b> {{ getUTCDateString(pc.departure) }} </div>
                              <div class="tooltip-rows"><b>Reason: </b> {{ toTitleCase(pc.reason) }} </div>
                              <div v-if="pc.quantity" class="tooltip-rows"><b>Quantity: </b> {{ pc.quantity }} MT </div>
                              <div v-if="!pc.quantity" class="tooltip-rows"><b>Quantity: </b> - </div>
                              <div v-if="pc.operator" class="tooltip-rows"><b class="mr-2">Operator: </b> {{ toTitleCase(pc.operator) }} </div>
                              <div v-if="!pc.operator" class="tooltip-rows"><b class="mr-2">Operator: </b> - </div>
                            </span>
                          </v-tooltip>

                        </div>
                        
                        <div v-for="(pc, index) in getSegments(item.value)" :key="index">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                              v-bind="attrs"
                                v-on="on"
                              class="pcsegment"
                              :class="{noArrivalToBerth: checkIfNoArrivalToBerth(pc)}"
                              :style= "`margin-left: ${pc.daysFromFirstToBirthed*dayInPixels}px;  width: ${pc.berthduration*dayInPixels}px; background-color: ${getOwnerColor};`">

                              <div
                                v-bind="attrs"
                                v-on="on"
                                style="cursor: pointer; width: fit-content; white-space: nowrap; padding-left: 2px; padding-right: 2px; font-size: 8px; position:relative; bottom: 12px; display: table;"
                              >
                                {{pc.port.trim()}}
                              </div>
                            </div>
                            </template>
                            <span class="text-left tooltip">
                              <div><b>{{ pc.port }}</b><i> ({{ toTitleCase(pc.vessel) }})</i></div>
                              <div class="tooltip-rows"><b>Arrival: </b>{{ getUTCDateString(pc.arrival) }} </div>
                              <div class="tooltip-rows"><b>Berthed: </b> {{ getUTCDateString(pc.berthed) }} </div>
                              <div class="tooltip-rows"><b>Departure: </b> {{ getUTCDateString(pc.departure) }} </div>
                              <div class="tooltip-rows"><b>Reason: </b> {{ toTitleCase(pc.reason) }} </div>
                              <div v-if="pc.quantity" class="tooltip-rows"><b>Quantity: </b> {{ pc.quantity }} MT </div>
                              <div v-if="!pc.quantity" class="tooltip-rows"><b>Quantity: </b> - </div>
                              <div v-if="pc.operator" class="tooltip-rows"><b class="mr-2">Operator: </b> {{ toTitleCase(pc.operator) }} </div>
                              <div v-if="!pc.operator" class="tooltip-rows"><b class="mr-2">Operator: </b> - </div>
                            </span>
                          </v-tooltip>
                          
                        </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          </v-card>
      </v-col>
    </v-row>
    <v-row v-if="getDateFilteredSchedules && getDateFilteredSchedules[0] && getDateFilteredSchedules[0].APIRequestTime">
        <span style="font-size: 11px;">{{getDateFilteredSchedules[0].envName }} | GUI: v{{ require('../../package.json').version }}&nbsp;| API: v{{getDateFilteredSchedules[0].APIv }} | Data loaded: {{ new Date(getDateFilteredSchedules[0].APIRequestTime).toLocaleString("en-uk") }}</span>
      </v-row>
  </v-container>
</template>

<script>
import DateRangeInput from '../components/DateRangeInput.vue'
import ToggleMinimized from '../components/ToggleMinimized.vue'
import { toTitleCase, getOwnerColor, getOwnerColorLight, getUTCDateString } from "../components/Tools"
export default {
  name: "SchedulePlanning",
  props: [],
  components: { DateRangeInput, ToggleMinimized },
  data: () => ({
    model: [],
    dates: [],
    dateRange: {
      startDate: null,
      endDate: null
    },
    dayInPixels: 23,
    measureStick: 0,
    loading: false,
    minimizedTrades: [],
    selectedTrades: [],
    selectedPorts: [],
    selectedVessels: [],
    selectedAreas: [],
  }),
  mounted: function () {
    this.loading = true
    this.init().then(() => {
      this.dateRange.startDate = new Date()
      this.dateRange.endDate = this.getDefaultStartDate(60)
      this.model = this.generateModel
      this.dates = this.generateTimeline(this.model)
      this.loading = false 
      this.$nextTick(() => {
        this.measureStick = this.$refs.measurestick[0].getBoundingClientRect().width
        this.calculateDayWidth()
      })
    })
  },
  beforeUpdate() {
    this.calculateDayWidth()
  },
  computed: {
    getOwnerColor, getOwnerColorLight,
    getPaddingX() {
      return this.$vuetify.breakpoint.smAndDown ? 5 : 15
    },
    generateModel() {
      let data = this.getDateFilteredSchedules
      if (this.selectedAreas.length > 0) {
        data = data.filter(item => this.selectedAreas.includes(item.AreaCode))
      }
      if (this.selectedPorts.length > 0) {
        data = data.filter(item => this.selectedPorts.includes(item.PortName))
      }      
      if (this.selectedVessels.length > 0) {
        data = data.filter(item => this.selectedVessels.includes(item.VesselName))
      }
      const filteredData = data.filter(pc => (pc.ArrivalDate != pc.DepartureDate) && (pc.BerthedDate != pc.DepartureDate))
      let trades =  [... new Set(filteredData.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.TradeCode))]
                        .filter(m => this.selectedTrades.length==0 || this.selectedTrades.includes(m))
                        .sort((a,b) => (a> b) ? 1 : ((b > a) ? -1 : 0))
      let output = []

      for(let trade of trades){
        output = [...output, {type: 'Trade', value: trade}]
        let pcs = filteredData.filter(f=> f.TradeCode === trade)
        let vessels = [... new Set(pcs.map(item => item.VesselName))]
          .sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
        for(let vessel of vessels) {
          let vesselPCS = pcs.filter(f=> f.VesselName === vessel)
          output.push({type:'Vessel', VesselName: vessel, trade: trade, value: vesselPCS})
       }
      }
      
      let first = new Date(this.dateRange.startDate)
      first.setHours(0)
      first.setMinutes(0)
      first.setSeconds(0)
      output.push({type: 'first', value: first.toISOString()})
      output.push({type: 'last', value: this.dateRange.endDate})
      return output
    },
    getDateFilteredSchedules() {
      let schedules = this.$store.state.schedules
      if(!schedules) return []
      return schedules
        .filter(item => new Date(this.dateRange.startDate) <= new Date(item.DepartureDate) && new Date(this.dateRange.endDate) >= new Date(item.ArrivalDate))
    },
    getArea(){
      let schedules = this.getDateFilteredSchedules
      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      let area =  [... new Set(schedules.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.AreaCode))].sort((a,b) => (a> b) ? 1 : ((b > a) ? -1 : 0))
      return area
    },
    getTrades(){
      let schedules = this.getDateFilteredSchedules
      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      let trades =  [... new Set(schedules.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.TradeCode))].sort((a,b) => (a> b) ? 1 : ((b > a) ? -1 : 0))
      return trades
    },    
    getPorts(){
      let schedules = this.getDateFilteredSchedules
      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      let ports =  [... new Set(schedules.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.PortName))].sort((a,b) => (a> b) ? 1 : ((b > a) ? -1 : 0))
      return ports
    },
    getVessels(){
      let schedules = this.getDateFilteredSchedules
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      let vessels =  [... new Set(schedules.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.VesselName))].sort((a,b) => (a> b) ? 1 : ((b > a) ? -1 : 0))
      return vessels
    },    
    getMinDate() {
      if (this.dateRange.endDate) {
        let end = new Date(this.dateRange.endDate)
        return new Date(end.setDate(end.getDate() - 1000))
      }
      return null
    },
    getMaxDate() {
      if (this.dateRange.startDate) {
        let start = new Date(this.dateRange.startDate)
        return new Date(start.setDate(start.getDate() + 1000))
      }
      return null
    },
  },
  methods: {
    toTitleCase, getUTCDateString,
    tradeHidden(vessel){
      return this.minimizedTrades.includes(vessel.trade)
    },
    checkIfNoArrivalToBerth(pc){
      return pc.arrival.getTime() == pc.berthed.getTime()
    },
    updateView() {
      this.model = this.generateModel
      this.dates = this.generateTimeline(this.model)
      this.$nextTick(() => {
        this.calculateDayWidth()
      })
    },
    getDefaultStartDate(days){
      let today = new Date()
      today.setDate(today.getDate()+days)
      return today
    },
    calculateDayWidth(){      
      if(!this.measureStick || !this.dates) {
        return
      }
      let days = this.dates.length
      let length = this.measureStick 
      let width = length/days
      if (width < 23) {
        this.dayInPixels = 23
      } else {
        this.dayInPixels = width
      }
    },
    generateTimeline(modelArr) {
      let firstDate = modelArr.find(f=> f.type=='first').value
      let lastDate = modelArr.find(f=> f.type=='last').value
      if(!firstDate || !lastDate) {
        return []
      }
      let dateArr = []
      let first = new Date(firstDate)
      let last = new Date(lastDate)
      let it = new Date(first)
      while(it <= last){
        dateArr.push(new Date(it))
        it.setDate(it.getDate()+1)
      }
      return dateArr
    },
    getSegments(pcs){
      let firstDate = new Date(this.model.find(f=> f.type=='first').value)
      let lastDate = new Date(this.model.find(f=> f.type=='last').value)
      let result = []
      for (let pc of pcs) {
        let berthed = new Date(pc.BerthedDate)
        let arrival = new Date(pc.ArrivalDate)
        let departure = new Date(pc.DepartureDate)
        let reason = pc.ReasonForCall
        let quantity = Math.round(pc.Quantity)
        let operator = pc.OperatorName
        let duration = (berthed.getTime() - arrival.getTime())/(1000 * 60 * 60 * 24)
        let berthduration = (departure.getTime() - berthed.getTime())/(1000 * 60 * 60 * 24)
        let daysFromFirstToArrival = (arrival.getTime() - firstDate.getTime() )/(1000 * 60 * 60 * 24)
        let daysFromFirstToBirthed = (berthed.getTime() - firstDate.getTime())/(1000 * 60 * 60 * 24)
        if(arrival > lastDate || departure<firstDate){
          continue
        }
        result.push({port: pc.PortName, daysFromFirstToArrival, duration, berthed, arrival, departure, reason, quantity, operator, berthduration, daysFromFirstToBirthed, vessel: pc.VesselName})
      }
      result = result.sort((a,b) => (a.daysFromFirstToArrival > b.daysFromFirstToArrival) ? 1 : ((b.daysFromFirstToArrival > a.daysFromFirstToArrival) ? -1 : 0))
      return result
    },

    getShortDate(date) {
      return date.toLocaleDateString("en-US", {day: 'numeric'})
    },
    getDayColor(date) {
      let day = date.getDay()
      let ownerColor = this.getOwnerColor
      return (day === 0 | day === 6) ? "indianred" : ownerColor
    },
    getMonth(date) {
      return date.toLocaleDateString("en-US", {month: 'long'})
    },
    isFirst(day, index){
      return day==1 || (index==0 && day<25)
    },
    init() {
      this.$store.dispatch("showLoader")
      return Promise.all([
        this.$store.dispatch("getSchedules"),
      ]).then( () => {
        this.$store.dispatch("hideLoader")
      }).catch((e) => {
        if(e.response && e.response.data.message.err.originalError)
          this.$router.push({name: 'Error', query: {error: e.response.data.message.err.originalError.message}})
        else
          this.$store.dispatch('showError', e.message)
      })
    }
  }
}

</script>

<style lang="scss">
.dense {
  height: 32px !important;
}
.tablestyle {
  width: 100%;
}
.noArrivalToBerth {
  border-radius: 6px 6px 6px 6px !important;
}
.traderow {
  background-color: #f3f3f3;
  font-size: 12px !important; 
  font-weight: bold; 

}
.vesselcell {
  background-color: #00000008; 
  font-size: 12px !important;
  white-space: nowrap; 
  border-right: solid 1px lightgrey;
}
.input {
  font-size: 14px;
  background-color: white !important;
}
.tooltip {
  font-size: 12px;
}
.tooltip-rows {
  display: flex;
  justify-content: space-between;
  min-width: 200px;
}
.pcsegment {
  position: absolute;
  cursor: pointer;
  border-right: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-radius: 0 6px 6px 0;
  height: 15px;
  margin-top: 12px;
  margin-bottom: 4px;
}
.berthpcsegment {
  position: absolute;
  cursor: pointer;
  border-left: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-radius: 6px 0 0 6px;
  height: 15px;
  margin-top: 12px;
  margin-bottom: 4px;
}
html {
  overflow: hidden !important;
}
.v-data-table__wrapper {
    overflow: visible;
}
</style>|