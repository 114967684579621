<template>
    <v-dialog
      :width="$vuetify.breakpoint.lgAndDown ? '80%' : '60%'"
      v-if="data"
      v-model="showDialog"
      transition="dialog-top-transition"
    >
      <v-card
        class="text-left justify-center"
      >
        <v-toolbar
          dark
          elevation="0"
          tile
          :color="ownerColor"
          height="50px"
        >
          <v-toolbar-title>
            <h5 class="white--text">PDF archive for trade {{data}}</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="closeHandler"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>

          <Files :id="data" :ownerColor="ownerColor"></Files>

        </v-card-text>

      </v-card>
    </v-dialog>
</template>

<script>
    import Files from './FilesDialogContent.vue'

    export default {
      name: "FilesDialog",
      props: ['data', 'closeHandler', 'ownerColor'],
      components: { Files },
      data: () => ({
        loaded: false
      }),
      computed: {
        showDialog: {
          get() {
            return this.data != null
          },
          // setter
          set() {
            // Note: we are using destructuring assignment syntax here.
            this.close()
          }
        },
      },
      methods: {
        close(){
          this.closeHandler()
          this.loaded=true
        },
      }
    }

    </script>

<style lang="scss">

.v-toolbar__content {
  padding-right: 0;
}

</style>|