<template>
  <v-container :fluid="this.$vuetify.breakpoint.lgAndDown" :class="`px-${getPaddingX} py-8`" style="height: 100%;">
    <v-row class="d-flex justify-space-between align-center px-3">
      <v-col lg="6" sm="12" class="px-0">
        <v-autocomplete
            v-model="vessel"
            label="Vessel"
            outlined
            hide-details
            dense
            clearable
            :color="getOwnerColor"
            :items="vesselList"
            item-text="VesselName"
            item-value="VesselName"
            class="dropdown"
            @change="updateUrl"
        >
        </v-autocomplete>
      </v-col>
      <v-col lg="6" sm="12" class="text-right pa-0 mt-3">

        <vue-excel-xlsx
            :data="excelList"
            :columns="excelColumns"
            :file-name="`Schedule vessel ${this.vessel}`"
            :file-type="'xlsx'"
            :sheet-name="`Vessel ${this.vessel}`"
            >
            <span class="export_btn_title">Generate EXCEL</span>
            <v-btn size="30" variant="text" icon color="green"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
        </vue-excel-xlsx>
        <v-btn :disabled="fileUploading" style="font-size: 11px;" elevation="0" @click="generatePDF">
        <span>Generate pdf</span>
        <v-icon v-if="!fileUploading" :color="getOwnerColor" class="ml-2">mdi-file-pdf-box</v-icon>
        <v-progress-circular
            v-if="fileUploading"
            class="ml-2"
            :value="fileUploadPercentage"
            :indeterminate="fileUploadIndeterminate"
            :width="1"
            :size="18"
            :color="getOwnerColor"
        >
          {{ percentOrNothing }}
        </v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <v-progress-circular
            v-if="loading"
            indeterminate
            :color="getOwnerColor"
            class="mt-8"
          ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!loading"  class="pt-0">
        <v-card flat class="rounded-lg pa-4" transition="fade-transition" style="overflow:auto;">
          <v-data-table
            :items="getSchedule"
            :headers="headers"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            class="vessel-table"
          >
          <template v-slot:[`item.PortName`]="{ item }">

            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                {{ item.PortName ? toTitleCase(item.PortName) : '-' }}
              </span>
            </template>
            <span>{{ item.DataSource }}</span>
          </v-tooltip>
          </template>          
          <template v-slot:[`item.AgentName`]="{ item, index }">
            <div style="max-width: 150px; white-space: pre-line;">{{ item.AgentName ? toTitleCase(item.AgentName) : '-'}}
            <v-dialog v-model="agentShowDetails[index]" width="40%" hide-overlay>
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon x-small @click="showAgentDetails(index)">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text style="text-align:left; padding-top: 10px;">  
                    <span :ref=setAgentTextRef(index) v-html=populateAgentDetails(item)></span>                  
                  </v-card-text>
                  <v-card-actions style="display: block;">
                    <div style="height:45px">
                      <v-btn style="float:left;" @click="copyToClipboard(index)">Copy to clipboard</v-btn>
                      <v-btn style="float:right" @click="hideAgentDetails(index)">Close</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
          <template v-slot:[`item.AgentPhone`]="{ item }">
            <div style="max-width: 195px; white-space: pre-line;">
              {{ item.AgentPhone ? item.AgentPhone : ''}}
            </div>
          </template>
          <template v-slot:[`item.OperatorName`]="{ item }">
            {{ item.OperatorName ? toTitleCase(item.OperatorName) : '-'}}
          </template>
          <template v-slot:[`item.ArrivalDate`]="{ item }">
            {{ getISO8601dateFromDate(item.ArrivalDate) }}
          </template>
          <template v-slot:[`item.BerthedDate`]="{ item }">
            {{ getISO8601dateFromDate(item.BerthedDate) }}
          </template>
          <template v-slot:[`item.DepartureDate`]="{ item }">
            {{ getISO8601dateFromDate(item.DepartureDate) }}
          </template>
          <template v-slot:[`item.ReasonForCall`]="{ item }">
            {{ item.ReasonForCall ? toTitleCase(item.ReasonForCall) : '-'}}
          </template>
          <template v-slot:[`item.Quantity`]="{ item, index }">
            {{ item.Quantity > 0 ? Math.round(item.Quantity) + ' MT' : '-' }}
            
            <v-dialog v-model="commodityShowDetails[index]" width="40%" hide-overlay>
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon x-small @click="showCommodityDetails(index)">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <v-data-table :items="getOrderedCommodityDetails(item)" :headers="headers_commodity" hide-default-footer disable-pagination
                      mobile-breakpoint="0">
                      <template v-slot:[`item.Q_total`]="{ item }">
                        {{ item.Q_total > 0 ? Math.round(item.Q_total) + ' ' + item.CargoUnit : '-' }}
                      </template>  
                      <template v-slot:[`item.PortName`]="{ item }">
                        {{ item.PortName ? toTitleCase(item.PortName) : '-' }}
                      </template>   
                      <template v-slot:[`item.ReasonForCall`]="{ item }">
                        {{ item.ReasonForCall ? toTitleCase(item.ReasonForCall) : '-' }}
                      </template> 
                    </v-data-table> 
                  </v-card-text>
                  <v-card-actions>
                    <v-btn block @click="hideCommodityDetails(index)">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

           </template>
        </v-data-table>
        </v-card>
        </v-col>
      </v-row>
      <v-row v-if="getSchedule && getSchedule[0] && getSchedule[0].APIRequestTime">
        <span style="font-size: 11px;">{{getSchedule[0].envName }} | GUI: v{{ require('../../package.json').version }}&nbsp;| API: v{{getSchedule[0].APIv }} | Data loaded: {{ new Date(getSchedule[0].APIRequestTime).toLocaleString("en-uk") }}</span>
      </v-row>

      <v-card flat class="pa-4 rounded-lg d-none" style="width: 100%;">
        <pdf-table
          :schedule="getSchedule"
          :headers="headers"
          :isTrade="false"
          ref="exportTable"
          />
      </v-card>
  </v-container>
</template>

<script>
import { toTitleCase, getOwnerColor, getISO8601dateFromDate } from "../components/Tools"
import PdfTable from "../components/PdfTable.vue"
import VueExcelXlsx from '@/components/VueExcelXlsx'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
const urls = require('../../config/urls')
export default {
  name: "SailingScheduleByVessel",
  props: [],
  components: { PdfTable, VueExcelXlsx} ,
  data: () => ({
    commodityShowDetails: [],
    agentShowDetails: [],
    loading: false,
    vessel: null,
    g2img: '',
    fileUploading: false,
    fileUploadIndeterminate: false,
    fileUploadPercentage: 0,
  }),
  watch: {
  },
  mounted: function () {
    this.loading = true
    this.init().then(() => {
      this.loading = false
      this.g2img = `${urls['IMG']}/g2ocean.png`
      if(this.$route.params.id || this.$route.params.id == 'undefined') {
        this.vessel = this.$route.params.id
      }
    })
  },
  computed: {    
    getOwnerColor,
    getPaddingX() {
      return this.$vuetify.breakpoint.smAndDown ? 5 : 15
    },
    percentOrNothing(){
      return this.fileUploadIndeterminate || this.fileUploadPercentage<1 ? '' : this.fileUploadPercentage
    },
    vesselList() {
      if(!this.$store.state.schedules) return []
      let vessels = [... new Set(this.$store.state.schedules.map(f => f.VesselName))]
      return vessels.sort((a, b) => a.localeCompare(b))
    },
    getSchedule() {
      let data = this.$store.state.schedules.filter(f => f.VesselName == this.vessel).sort((a, b) => a.ArrivalDate?.localeCompare(b.ArrivalDate))
      return data
    },
    headers() {
      return [
        { text: "Port", value: "PortName", sortable: false, filterable: false, groupable: true},
        { text: "Voyage #", value: "VesselVoyageNo", sortable: false, filterable: false, groupable: true},
        { text: "Trade Code", value: "TradeCode", sortable: false, filterable: false, groupable: true},
        { text: "Arrival", value: "ArrivalDate", sortable: false, filterable: false, groupable: true},
        { text: "Berthed", value: "BerthedDate", sortable: false, filterable: false, groupable: true},
        { text: "Departure", value: "DepartureDate", sortable: false, filterable: false, groupable: true},
        { text: "Reason", value: "ReasonForCall", sortable: false, filterable: false, groupable: true},
        { text: "Quantity", value: "Quantity", sortable: false, filterable: false, groupable: true, align: 'end'},
        { text: "Agent", value: "AgentName", sortable: false, filterable: false, groupable: true},
        { text: "Agent contact", value: "AgentPhone", sortable: false, filterable: false, groupable: true},
        { text: "Operator", value: "OperatorName", sortable: false, filterable: false, groupable: true},
      ]
    },
    headers_commodity() {
      return [
        { text: "Port", value: "PortName", sortable: false, filterable: false, groupable: true},
        { text: "Reason", value: "ReasonForCall", sortable: false, filterable: false, groupable: true},
        { text: "Commodity", value: "Commodity", sortable: false, filterable: false, groupable: true},
        { text: "Quantity", value: "Q_total", sortable: false, filterable: false, groupable: true, align: 'end'},

      ]
    },
    todaysDate() {
      return new Date().toLocaleString("en-uk").slice(0,-3)
    },
    excelColumns(){
      return [
        {
            label: "Port",
            field: "PortName",
        },
        {
            label: "Voyage #",
            field: "VesselVoyageNo",
            width: 12,
        },
        {
            label: "Trade Code",
            field: "TradeCode",
            width: 12,
        },
        {
            label: "Arrival",
            field: "ArrivalDate",
            width: 25,
        },
        {
            label: "Berthed",
            field: "BerthedDate",
            width: 25,
        },
        {
            label: "Departure",
            field: "DepartureDate",
            width: 25,
        },
        {
            label: "Reason",
            field: "ReasonForCall",
            width: 15,
        },
        {
            label: "Quantity",
            field: "Quantity",
            width: 10,
        },
        {
            label: "Agent",
            field: "AgentName",
            width: 55,
            wrapText: true,
        },
        {
            label: "Agent contact",
            field: "AgentPhone",
            width: 45,
            wrapText: true,
        },
        {
            label: "Operator",
            field: "OperatorName",
            width: 25,
        },

      ]
    },
    excelList() {
      return JSON.parse(JSON.stringify(this.getSchedule)) 
    },
  },
  methods: {
    toTitleCase, getISO8601dateFromDate,
    setAgentTextRef(index) {
      return 'agent_'+index
    },
    populateAgentDetails(item) {
      let data = ''

      if(item['Ag.Name']) {
        data += `<span class="agent-text">${item['Ag.Name']}</span><br />`
      }

      if(item['Ag.Address']) {
        data += `<span class="agent-text">${item['Ag.Address']}</span><br />`
      }
            
      if(item['Ag.City'] || item['Ag.PostalCode']) {
        data += `<span class="agent-text">${item['Ag.City'] ?? ''} ${item['Ag.State'] ?? ''} ${item['Ag.PostalCode'] ?? ''}</span> <br />`
      }

      if(item['Ag.Country']) {
        data += `<span class="agent-text">${item['Ag.Country']}</span><br />`
      }

      const map_label_to_field = {
        'Mobile phone': 'Ag.MobilePhone',
        'Office phone': 'Ag.OfficePhone',
        'Email address': 'Ag.EmailAddress', 
        'Telefax number': 'Ag.TeleFaxNumber'
      }

      for(const label in map_label_to_field) {
        const value = item[map_label_to_field[label]] ?? undefined

        if(value) {
          data += `<span>${label}: <span class="agent-text">${value}</span></span><br />`
        }
      }


      if(!data) {
        data += 'No agent information.'
      }

      return data
    },
    async copyToClipboard(index) {
      try {

        let text_to_copy = this.$refs['agent_'+index].innerText;


        if (navigator.clipboard) {
          await navigator.clipboard.writeText(text_to_copy);
          console.log("Text copied to clipboard!");
        } else {
          const textarea = document.createElement("textarea");
          textarea.value = text_to_copy;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);
          console.log("Text copied to clipboard using fallback method!");
        }
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    },
    updateUrl(){
      this.$router.push({ name: this.$route.name, params: { id: this.vessel } })
    },

    hideCommodityDetails(index) {
      this.$set(this.commodityShowDetails, index, false);

    },
    showCommodityDetails(index) {
      for (let i = 0; i < this.commodityShowDetails.length; i++) {
        this.commodityShowDetails[i] = false;
      }

      this.$set(this.commodityShowDetails, index, true);
    },
    showAgentDetails(index) {
      for (let i = 0; i < this.agentShowDetails.length; i++) {
        this.agentShowDetails[i] = false;
      }

      this.$set(this.agentShowDetails, index, true);
    },
    hideAgentDetails(index) {
      this.$set(this.agentShowDetails, index, false);

    },
    hasCommodityDetails(item) {
      return !!item.CommodityDetails
    },
    getOrderedCommodityDetails(item) {
      let sorted_data = {}

      if(!item.CommodityDetails) return []

      sorted_data = JSON.parse(JSON.stringify(item.CommodityDetails))

      return sorted_data.sort((a, b) => {

        const commodityA = a.Commodity.toLowerCase()
        const commodityB = b.Commodity.toLowerCase()

        if (commodityA < commodityB) {
          return -1;
        }
        if (commodityA > commodityB) {
          return 1;
        }

        return 0;
      })

    },
    async generatePDF() {
      this.fileUploading = true
      const html = `<html><head>`+
        `<link rel="preconnect" href="https://fonts.googleapis.com">`+
        `<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>`+
        `<link href="https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap" rel="stylesheet">`+

        `<style>`+
        'th {font-size: 0.75rem; height: 32px; text-align: left; font-weight: normal;}'+
        '.header {margin-left: 40px; padding: 10px; padding-top: 40px; width: 100%; height: 50px; display: flex; flex-direction: row;}'+
        '.titlerow {display: flex; margin: auto; height: 100%;}'+
        '.title {width: 100%; display: flex; flex-direction: column; justify-content: space-between;}'+
        '.text {display: flex; margin: auto;}'+
        'img {height: 50px; display: inline-block;}'+
        `</style>`+
        `</head><body><div class="header"><img src="${urls.IMG}/g2ocean.png">`+
        `<div style="font-family: Arial, Helvetica, sans-serif; padding-left: 40px;" class="titlerow"><div class="title"><span class="text" style="font-weight: bold;">Sailing Schedule</span>` +
        `<span class="text">${this.vessel}</span></div></div>`+
        `<div><span class="text" style="color: grey; font-size: 12px; font-family: Arial, Helvetica, sans-serif; padding-right: 20px; margin-top:30px; margin-right: 80px;">${this.todaysDate} </span></div> ` +
        `</div>${this.$refs['exportTable'].$el.innerHTML}</body></html>`
      
      const obj = { data: html }
      const url = `${urls['FILES']}/pdf`
      this.fileUploadPercentage = 0
      const blob = await Vue.axios.post(url, obj , {
          headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt')
          },
          responseType: 'blob',
          onUploadProgress: (e) => {
            if(e.type=='progress'){
              const rawPercentage = e.loaded / e.total * 100
              this.fileUploadPercentage = rawPercentage.toFixed(2)
              if(this.fileUploadPercentage == 100) {
                this.fileUploadIndeterminate = true
              }
            }
          }
      }).catch(() => {
        this.snackMessage = 'Failed to generate pdf'
        this.snackbar = true
        this.fileUploading = false
        this.fileUploadIndeterminate = false
      })

      if(!blob) {
        return
      }

      const fileURL = window.URL.createObjectURL(blob.data);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `sailingSchedule_${this.$route.params.id}.pdf`);
      fileLink.click();
      window.URL.revokeObjectURL(fileURL);

      this.fileUploading = false
    },
    init() {
      this.$store.dispatch("showLoader")
      if(!this.$route.params.id){
          this.$router.push({ name: this.$route.name, params: { id: this.vesselList[0] } })
        }
      if(!this.port) {
        this.port = this.vesselList[0]
      }
      return Promise.all([
        this.$store.dispatch("getSchedules"),
      ]).then( () => {
        this.$store.dispatch("hideLoader")
      }).catch((e) => {
        if(e.response && e.response.data.message.err.originalError)
          this.$router.push({name: 'Error', query: {error: e.response.data.message.err.originalError.message}})
        else
          this.$store.dispatch('showError', e.message)
      })
    }
  },
}

</script>

<style lang="scss">

html {
  overflow: hidden !important;
} 
.vessel-table td{
  font-size: 12px !important;
  white-space: pre;
  text-align: left;
}
.export_btn_title {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.089286em;
}

.agent-text {
  color: black;
}
</style>|