<template>
    <div>
    <v-btn v-if="!isSelected" @click="select()" x-small icon>
        <v-icon >mdi-minus-box-outline</v-icon>
    </v-btn>
    <v-btn v-if="isSelected" @click="deselect()" x-small icon>
        <v-icon>mdi-plus-box-outline</v-icon>
    </v-btn>
</div>
</template>

<script>
export default {
  name: "ToggleMinimized",
  props: ['value', 'current'],
  components: {},
  created() {
  },
  data: () => ({
    internalState: [],
  }),
  computed: {
    isSelected() {
        return this.internalState.includes(this.current)
    }
  },
  mounted: function () {
    this.internalState = this.value
  },
  beforeUpdate() {
  },
  methods: {
    select(){
        this.internalState.push(this.current)
        this.$emit('input', this.internalState)
    },
    deselect(){
        let index = this.internalState.indexOf(this.current)
        this.internalState.splice(index,1)
        this.$emit('input', this.internalState)
    }
  },
};


</script>

<style lang="scss">
</style>