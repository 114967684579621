<template>
  <v-container :fluid="this.$vuetify.breakpoint.lgAndDown" :class="`px-${getPaddingX} py-8`" style="height: 100%;">
    <v-row>
      <v-col lg="7" class="d-flex">
        <v-autocomplete v-model="selectedAreas" :items="getArea" label="Area" outlined multiple class="input mr-3"
          clearable :color="getOwnerColor" hide-details dense @change="updateView()">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedAreas.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
        <v-autocomplete v-model="selectedTrades" :items="getTrades" label="Trade" outlined class="input mr-3" multiple
          clearable :color="getOwnerColor" hide-details dense @change="updateView()">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedTrades.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
        <v-autocomplete v-model="selectedPorts" :items="getPorts" label="Port" outlined class="input mr-3" clearable
          multiple :color="getOwnerColor" hide-details dense @change="updateView()">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedPorts.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
        <v-autocomplete v-model="selectedVessels" :items="vesselList" label="Vessel" outlined class="input mr-3" clearable
          multiple :color="getOwnerColor" hide-details dense @change="updateView()" item-text="VesselName"
          item-value="VesselName">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedVessels.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="auto" class="pt-4 d-flex">
        <date-range-input v-model="dateRange" :min="getMinDate" :max="getMaxDate" :ownerColor="getOwnerColor"
          @change="updateView()">
        </date-range-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <v-progress-circular v-if="loading" indeterminate :color="getOwnerColor" class="mt-8"></v-progress-circular>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!loading" class="pt-0">
        <v-card flat class="rounded-lg pa-4" transition="fade-transition" style="overflow:auto;">

          <v-alert v-if=too_many dense outlined type="warning">
            The number of the items that should be displayed is over <strong>1000</strong>.
            Please consider adjusting the <strong>filters</strong>.
          </v-alert>

          <v-data-table v-if=!too_many :items="table_data" :headers="headers" hide-default-footer disable-pagination
            mobile-breakpoint="0" class="vessel-table">
            <template v-slot:[`item.PortName`]="{ item }">
              {{ item.PortName ? toTitleCase(item.PortName) : '-' }}
            </template>
            <template v-slot:[`item.AgentName`]="{ item }">
              <span class="text-wrap">{{ item.AgentName ? toTitleCase(item.AgentName) : '-' }}</span>
            </template>
            <template v-slot:[`item.AgentPhone`]="{ item }">
              <span class="text-wrap">{{ item.AgentPhone ? item.AgentPhone : '-' }}</span>
            </template>
            <template v-slot:[`item.OperatorName`]="{ item }">
              <span class="text-wrap">{{ item.OperatorName ? toTitleCase(item.OperatorName) : '-' }}</span>
            </template>
            <template v-slot:[`item.ArrivalDate`]="{ item }">
              {{ getISO8601dateFromDate(item.ArrivalDate) }}
            </template>
            <template v-slot:[`item.BerthedDate`]="{ item }">
              {{ getISO8601dateFromDate(item.BerthedDate) }}
            </template>
            <template v-slot:[`item.DepartureDate`]="{ item }">
              {{ getISO8601dateFromDate(item.DepartureDate) }}
            </template>
            <template v-slot:[`item.ReasonForCall`]="{ item }">
              {{ item.ReasonForCall ? toTitleCase(item.ReasonForCall) : '-' }}
            </template>
            <template v-slot:[`item.Quantity`]="{ item, index }">
              {{ item.Quantity > 0 ? Math.round(item.Quantity) + ' MT' : '-' }}

              <v-dialog v-model="commodityShowDetails[index]" width="40%" hide-overlay>
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon x-small
                    @click="showCommodityDetails(index)">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <v-data-table :items="getOrderedCommodityDetails(item)" :headers="headers_commodity"
                      hide-default-footer disable-pagination mobile-breakpoint="0">
                      <template v-slot:[`item.Q_total`]="{ item }">
                        {{ item.Q_total > 0 ? Math.round(item.Q_total) + ' ' + item.CargoUnit : '-' }}
                      </template>
                      <template v-slot:[`item.PortName`]="{ item }">
                        {{ item.PortName ? toTitleCase(item.PortName) : '-' }}
                      </template>
                      <template v-slot:[`item.ReasonForCall`]="{ item }">
                        {{ item.ReasonForCall ? toTitleCase(item.ReasonForCall) : '-' }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn block @click="hideCommodityDetails(index)">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { toTitleCase, getOwnerColor, getISO8601dateFromDate } from "../components/Tools"
import DateRangeInput from '../components/DateRangeInputHistorical.vue'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
const urls = require('../../config/urls')
export default {
  name: "SailingScheduleByVessel",
  props: [],
  components: { DateRangeInput },

  data: () => ({
    commodityShowDetails: [],
    max_records: 1000,
    too_many: false,
    loading: false,
    vessel: null,
    g2img: '',
    fileUploading: false,
    fileUploadIndeterminate: false,
    fileUploadPercentage: 0,
    table_data: [],
    model: [],
    dates: [],
    dateRange: {
      startDate: null,
      endDate: null
    },
    dayInPixels: 23,
    measureStick: 0,
    minimizedTrades: [],
    selectedTrades: [],
    selectedPorts: [],
    selectedVessels: [],
    selectedAreas: [],
  }),

  watch: {
    'selectedAreas': function(newValue) {
      this.updateQueryString({ areas: newValue });
    },
    'selectedTrades': function(newValue) {
      this.updateQueryString({ trades: newValue });
    },
    'selectedPorts': function(newValue) {
      this.updateQueryString({ ports: newValue });
    },
    'selectedVessels': function(newValue) {
      this.updateQueryString({ vessels: newValue });
    },
    'dateRange.startDate': function(newValue) {
      this.updateQueryString({ from: newValue });
    },
    'dateRange.endDate': function(newValue) {
      this.updateQueryString({ to: newValue });
    },
  },
  mounted: function () {
    this.loading = true
    this.init().then(() => {
      if (this.$route.params.id || this.$route.params.id == 'undefined') {
        this.vessel = this.$route.params.id
      }

      this.initializeFromQueryString();

      if(!this.dateRange.startDate) this.dateRange.startDate = new Date()
      if(!this.dateRange.endDate) this.dateRange.endDate = this.getDefaultStartDate(60)

      this.generateModel()
      this.loading = false
      this.g2img = `${urls['IMG']}/g2ocean.png`
    })
  },
  computed: {
    getOwnerColor,
    getPaddingX() {
      return this.$vuetify.breakpoint.smAndDown ? 5 : 15
    },
    percentOrNothing() {
      return this.fileUploadIndeterminate || this.fileUploadPercentage < 1 ? '' : this.fileUploadPercentage
    },
    vesselList() {
      if (!this.$store.state.historical) return []
      let vessels = [... new Set(this.$store.state.historical.map(f => f.VesselName))]
      return vessels.sort((a, b) => a.localeCompare(b))
    },

    headers() {
      return [
        { text: "Vessel", value: "VesselName", sortable: false, filterable: false, groupable: true },
        { text: "Port", value: "PortName", sortable: false, filterable: false, groupable: true },
        { text: "Arrival", value: "ArrivalDate", sortable: false, filterable: false, groupable: true },
        { text: "Berthed", value: "BerthedDate", sortable: false, filterable: false, groupable: true },
        { text: "Departure", value: "DepartureDate", sortable: false, filterable: false, groupable: true },
        { text: "Reason", value: "ReasonForCall", sortable: false, filterable: false, groupable: true },
        { text: "Quantity", value: "Quantity", sortable: false, filterable: false, groupable: true, align: 'end' },
        { text: "Agent", value: "AgentName", sortable: false, filterable: false, groupable: true },
        { text: "Agent contact", value: "AgentPhone", sortable: false, filterable: false, groupable: true },
        { text: "Operator", value: "OperatorName", sortable: false, filterable: false, groupable: true },
      ]
    },
    headers_commodity() {
      return [
        { text: "Port", value: "PortName", sortable: false, filterable: false, groupable: true },
        { text: "Reason", value: "ReasonForCall", sortable: false, filterable: false, groupable: true },
        { text: "Commodity", value: "Commodity", sortable: false, filterable: false, groupable: true },
        { text: "Quantity", value: "Q_total", sortable: false, filterable: false, groupable: true, align: 'end' },

      ]
    },
    todaysDate() {
      return new Date().toLocaleString("en-uk").slice(0, -3)
    },
    getDateFilteredSchedules() {
      let schedules = this.$store.state.historical

      if (!schedules) return []

      return schedules
        .filter(item => new Date(this.dateRange.startDate) <= new Date(item.DepartureDate) && new Date(this.dateRange.endDate) >= new Date(item.ArrivalDate))
    },
    getArea() {
      let schedules = this.getDateFilteredSchedules

      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      let area = [... new Set(schedules.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.AreaCode))].sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
      return area
    },
    getTrades() {
      let schedules = this.getDateFilteredSchedules
      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      let trades = [... new Set(schedules.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.TradeCode))].sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
      return trades
    },
    getPorts() {
      let schedules = this.getDateFilteredSchedules
      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      let ports = [... new Set(schedules.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.PortName))].sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
      return ports
    },
    getVessels() {
      let schedules = this.getDateFilteredSchedules
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      let vessels = [... new Set(schedules.filter(pc => pc.ArrivalDate != pc.DepartureDate).map(item => item.VesselName))].sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
      return vessels
    },
    getMinDate() {
      return new Date(new Date().setFullYear(new Date().getFullYear() - 6, 0, 1))
    },
    getMaxDate() {
      return new Date(new Date().setFullYear(new Date().getFullYear() + 2, 11, 31))

    },

  },
  methods: {
    toTitleCase, getISO8601dateFromDate,
    updateUrl() {
      this.$router.push({ name: this.$route.name, params: { id: this.vessel } })
    },
    hideCommodityDetails(index) {
      this.$set(this.commodityShowDetails, index, false);

    },
    showCommodityDetails(index) {
      for (let i = 0; i < this.commodityShowDetails.length; i++) {
        this.commodityShowDetails[i] = false;
      }

      this.$set(this.commodityShowDetails, index, true);
    },
    hasCommodityDetails(item) {
      return !!item.CommodityDetails
    },
    getOrderedCommodityDetails(item) {
      let sorted_data = {}

      if (!item.CommodityDetails) return []

      sorted_data = JSON.parse(JSON.stringify(item.CommodityDetails))

      return sorted_data.sort((a, b) => {

        const commodityA = a.Commodity.toLowerCase()
        const commodityB = b.Commodity.toLowerCase()

        if (commodityA < commodityB) {
          return -1;
        }
        if (commodityA > commodityB) {
          return 1;
        }

        return 0;
      })

    },
    getDefaultStartDate(days) {
      let today = new Date()
      today.setDate(today.getDate() + days)
      return today
    },
    generateModel() {
      let data = this.getDateFilteredSchedules
      if (this.selectedAreas.length > 0) {
        data = data.filter(item => this.selectedAreas.includes(item.AreaCode))
      }
      if (this.selectedTrades.length > 0) {
        data = data.filter(item => this.selectedTrades.includes(item.TradeCode))
      }
      if (this.selectedPorts.length > 0) {
        data = data.filter(item => this.selectedPorts.includes(item.PortName))
      }
      if (this.selectedVessels.length > 0) {
        data = data.filter(item => this.selectedVessels.includes(item.VesselName))
      }


      if (data.length > this.max_records) {
        this.too_many = true
      } else {
        this.too_many = false

        this.table_data = data.sort((a, b) => a.ArrivalDate?.localeCompare(b.ArrivalDate))
      }

    },
    updateView() {
      this.generateModel()
    },
    init() {
      this.$store.dispatch("showLoader")

      return Promise.all([
        this.$store.dispatch("getHistorical"),
      ]).then(() => {
        this.$store.dispatch("hideLoader")
      }).catch((e) => {
        if (e.response && e.response.data.message.err.originalError)
          this.$router.push({ name: 'Error', query: { error: e.response.data.message.err.originalError.message } })
        else
          this.$store.dispatch('showError', e.message)
      })
    },
    initializeFromQueryString() {
      const urlParams = new URLSearchParams(window.location.search);

      this.dateRange.startDate = (urlParams.get('from') && new Date(urlParams.get('from'))) ?? null;
      this.dateRange.endDate = (urlParams.get('to') && new Date(urlParams.get('to'))) ?? null

      this.selectedAreas = (urlParams.get('areas') && urlParams.get('areas').split(',')) ?? []
      this.selectedTrades = (urlParams.get('trades') && urlParams.get('trades').split(',')) ?? []
      this.selectedPorts = (urlParams.get('ports') && urlParams.get('ports').split(',')) ?? []
      this.selectedVessels = (urlParams.get('vessels') && urlParams.get('vessels').split(',')) ?? []
    },
    updateQueryString(changes) {
      const newUrl = new URL(window.location.href);
      Object.keys(changes).forEach(key => {
        if (changes[key] !== null) {
          newUrl.searchParams.set(key, changes[key]);
        } else {
          newUrl.searchParams.delete(key);
        }
      });
      window.history.pushState({}, '', newUrl.toString());
    },
  },
}

</script>

<style lang="scss" scoped>
html {
  overflow: hidden !important;
}

.vessel-table td {
  font-size: 12px !important;
  white-space: pre;
  text-align: left;
}
</style>|