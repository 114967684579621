<template>
  <v-container :fluid="this.$vuetify.breakpoint.lgAndDown" :class="`px-${getPaddingX} py-8`" style="height: 100%;">
    <v-row class="justify-start align-end">
      <v-col lg="2" md="6" class="pr-3">                    
        <v-autocomplete
            v-model="selectedAreas"
            label="Area"
            outlined
            hide-details
            dense
            multiple
            clearable
            :color="getOwnerColor"
            :items="areaList"
            item-text="AreaCode"
            item-value="AreaCode"
            class="dropdown"
        >
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedAreas.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col lg="2" md="6" class="pl-0 pr-3">
        <v-autocomplete
            v-model="selectedTrades"
            label="Trade"
            outlined
            hide-details
            dense
            multiple
            clearable
            :color="getOwnerColor"
            :items="tradeList"
            item-text="TradeCode"
            item-value="TradeCode"
            class="dropdown"
        >
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedTrades.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col lg="2" md="6" class="pl-0 pr-3">
        <v-autocomplete
            v-model="selectedPorts"
            label="Port"
            outlined
            hide-details
            dense
            multiple
            clearable
            :color="getOwnerColor"
            :items="portList"
            item-text="PortName"
            item-value="PortName"
            class="dropdown"
        >
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedPorts.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col lg="2" md="6" class="pr-3 pl-0">
        <v-autocomplete
            v-model="selectedVessels"
            label="Vessel"
            outlined
            hide-details
            dense
            multiple
            clearable
            :color="getOwnerColor"
            :items="vesselList"
            item-text="VesselName"
            item-value="VesselName"
            class="dropdown"
        >
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item }}</span>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ selectedVessels.length - 1 }} more)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col lg="2" md="6" class="px-0">
        <v-btn-toggle
          v-model="toggleDate"
          mandatory
          dense
          borderless
          :color="getOwnerColor"
          background-color="grey"
        >
          <v-btn height="40px" value="arrival" style="font-size: 12px;">
            Arrival
          </v-btn>
          <v-btn height="40px" value="departure" style="font-size: 12px;">
            Departure
          </v-btn>
          <v-btn height="40px" value="all" style="font-size: 12px;">
            All
          </v-btn>
        </v-btn-toggle>
        </v-col>

    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-6">
        <v-progress-circular
            v-if="loading"
            indeterminate
            class="mt-6"
            :color="getOwnerColor"
          ></v-progress-circular>
        <v-card v-if="!loading" flat class="rounded-lg px-6 pb-6 pt-1" transition="fade-transition">
          <v-sheet height="65" class="d-flex">
            <v-toolbar
              class="toolbar"
              flat
            >
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="$refs.calendar.next()"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title class="pl-4">
                {{ calendarTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                small
                elevation="0"
                @click="setToday"
              >
                Today
              </v-btn>
              </v-toolbar>
            </v-sheet>
            <v-calendar
              v-model="value"
              ref="calendar"
              type="month"
              :events="events"
              interval-count="0"
              :show-week="true"
              :color="getOwnerColorLight"
              >
              <template v-slot:event="{ event }">
              <div class="text-left ml-1">
                <span
                 @mouseover="(highlightedPort = event.port)" 
                 @mouseleave="highlightedPort = ''" 
                 :class="getHighlightClass(event.port)"
                 >
                 <b> {{ toTitleCase(event.port) }}</b>
                </span>
                <span class="black--text"> - </span>
                <span 
                  @mouseover="(highlightedVessel = event.vessel)" 
                  @mouseleave="highlightedVessel = ''" 
                  :class="getHighlightClass(event.vessel)"
                  >
                  <i> {{ toTitleCase(event.vessel) }} </i>
                </span>
              </div>
            </template>
          </v-calendar>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="events && events[0] && events[0].APIRequestTime && events[0].APIv">
        <span style="font-size: 11px;">{{events[0].envName }} | GUI: v{{ require('../../package.json').version }}&nbsp;| API: v{{events[0].APIv }} | Data loaded: {{ new Date(events[0].APIRequestTime).toLocaleString("en-uk") }}</span>
      </v-row>
  </v-container>
</template>

<script>
import { toTitleCase, getOwnerColor, getOwnerColorLight, getUserCompany } from "../components/Tools"
export default {
  name: "Calendar",
  props: [],
  components: { },
  data: () => ({
    value: '',
    calendarTitle: '',
    departureDate: false,
    loading: false,
    selectedAreas: [],
    selectedPorts: [],
    selectedVessels: [],
    selectedTrades: [],
    highlightedPort: null,
    highlightedVessel: null,
    toggleDate: '',
    classMap: {
      'gb': 'highlightedgb',
      'gs': 'highlightedgs',
      'g2o': 'highlightedg2o'
    }
  }),
  mounted: function () {
    this.loading = true
    this.init().then(() => {
      this.loading = false
    })
  },
  updated() {
    if (this.$refs.calendar) {
      this.calendarTitle = this.$refs.calendar.title
    }
  },
  computed: {
    getOwnerColor, getOwnerColorLight, getUserCompany,
    getPaddingX() {
      return this.$vuetify.breakpoint.smAndDown ? 5 : 15
    },
    events() {
      const data = this.$store.state.schedules
      if(!data) return []
      let events = [] 
      let that = this

      data.forEach(function(item) {
        if ((that.selectedAreas.length===0 && that.selectedTrades.length===0 && that.selectedPorts.length===0 && that.selectedVessels.length===0 ) 
         || ((that.selectedAreas.length > 0 ? that.selectedAreas.includes(item.AreaCode) : that.selectedAreas.length===0)
         && (that.selectedTrades.length > 0 ? that.selectedTrades.includes(item.TradeCode) : that.selectedTrades.length===0) 
         && (that.selectedPorts.length > 0 ? that.selectedPorts.includes(item.PortName) : that.selectedPorts.length===0)
         && (that.selectedVessels.length > 0 ? that.selectedVessels.includes(item.VesselName) : that.selectedVessels.length===0))) {
          if (that.toggleDate === 'arrival'){
          events.push({
            port: item.PortName,
            vessel: item.VesselName,
            start: new Date(item.ArrivalDate),
            color: '#DCDCDC',
            area: item.AreaCode,
            APIRequestTime: item.APIRequestTime,
            APIv: item.APIv,
            envName: item.envName
            })
          }
          if (that.toggleDate === 'departure') {
          events.push({
            port: item.PortName,
            vessel: item.VesselName,
            start: new Date(item.DepartureDate),
            color: '#DCDCDC',
            APIRequestTime: item.APIRequestTime,
            APIv: item.APIv,
            envName: item.envName,
            area: item.AreaCode
            })
          }
          if (that.toggleDate === 'all') {
            let daysArray = that.getDaysArray(item.ArrivalDate, item.DepartureDate)
            let port = item.PortName
            let vessel = item.VesselName
            let area = item.AreaCode
            daysArray.forEach(function(date) {
              events.push({
              port: port,
              vessel: vessel,
              start: date,
              color: '#DCDCDC',
              APIRequestTime: item.APIRequestTime,
              APIv: item.APIv,
              envName: item.envName,
              area: area
              })
            })
          }
        }
        })
      return events
    },
    portList() {
      if (!this.$store.state.schedules) return []
      let schedules = this.$store.state.schedules.filter(f => f.PortName != null)
      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      let ports = [... new Set(schedules.map(f => f.PortName))]
      return ports.sort((a, b) => a.localeCompare(b))
    },
    vesselList() {
      if (!this.$store.state.schedules) return []
      let schedules = this.$store.state.schedules.filter(f => f.VesselName != null)
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      let vessels = [... new Set(schedules.map(f => f.VesselName))]
      return vessels.sort((a, b) => a.localeCompare(b))
    },
    areaList() {
      if (!this.$store.state.schedules) return []
      let schedules = this.$store.state.schedules.filter(f => f.AreaCode != null)
      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedTrades.length > 0) {
        schedules = schedules.filter(pc => this.selectedTrades.includes(pc.TradeCode))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      let area = [... new Set(schedules.map(f => f.AreaCode))]
      return area.sort((a, b) => a.localeCompare(b))
    },
    tradeList() {
      if (!this.$store.state.schedules) return []
      let schedules = this.$store.state.schedules.filter(f => f.TradeCode != null)
      if (this.selectedVessels.length > 0) {
        schedules = schedules.filter(pc => this.selectedVessels.includes(pc.VesselName))
      }
      if (this.selectedPorts.length > 0) {
        schedules = schedules.filter(pc => this.selectedPorts.includes(pc.PortName))
      }
      if (this.selectedAreas.length > 0) {
        schedules = schedules.filter(pc => this.selectedAreas.includes(pc.AreaCode))
      }
      let trades = [... new Set(schedules.map(f => f.TradeCode))]
      return trades.sort((a, b) => a.localeCompare(b))
    }
  },
  methods: {
    toTitleCase,
    getDate(date) {
      return [date.getFullYear(), date.getMonth(), date.getDate()].join('-') + ' ' + [date.getHours(), date.getMinutes()].join(':')
    },
    setToday () {
      this.value = ''
    },
    getHighlightClass(item) {
      if (item === this.highlightedPort || item === this.highlightedVessel) {
        return this.classMap[this.getUserCompany]
      }
      return 'black--text'
    },
    getDaysArray(start, end) {
      let daysArray = []
      for(let date = new Date(start); date <= new Date(end); date.setDate(date.getDate()+1)){
          daysArray.push(new Date(date));
      }
      return daysArray
    },
    init() {
      this.$store.dispatch("showLoader")
      return Promise.all([
        this.$store.dispatch("getSchedules"),
        this.areaCode = null,
        this.portName = null,
        this.vesselName = null
      ]).then( () => {
        this.$store.dispatch("hideLoader")
      }).catch((e) => {
        if(e.response && e.response.data.message.err.originalError)
          this.$router.push({name: 'Error', query: {error: e.response.data.message.err.originalError.message}})
        else
          this.$store.dispatch('showError', e.message)
      })
    }
  }
}

</script>

<style lang="scss">

html {
  overflow: hidden !important;
}
 .v-toolbar__content {
  padding-left: 0 !important;
}
.dropdown {
  font-size: 13px;
}
.highlightedg2o {
  color: white;
  background-color: rgb(64, 132, 129);
  padding-left: 3px; 
  padding-right: 3px; 
  border-radius: 4px;
}
.highlightedgb {
  color: white;
  background-color: #254b65;
  padding-left: 3px; 
  padding-right: 3px; 
  border-radius: 4px;
}
.highlightedgs {
  color: white;
  background-color: #1976d2;
  padding-left: 3px; 
  padding-right: 3px; 
  border-radius: 4px;
}
.v-calendar-weekly__day-label {
  padding-bottom: 5px;
}
.v-calendar-weekly__weeknumber>small {
    font-weight: bold;
    font-size: 10px;
    color: grey;
}
.v-calendar-weekly__head-weekday {
  height: 15px;
  font-weight: bold;
  color: grey !important;
}
.v-calendar-weekly__head-weekday.v-present {
  color: rgb(64, 132, 129) !important;
}
</style>|